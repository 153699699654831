@media (max-width: 1440px) {
    .select-group{flex-direction:column;}
}
@media (max-width: 1199.98px) {
    .tabs-container{
        flex-direction: column;
    }
    .tabs-container .tabs-sections{
        flex: auto;
        max-width: 100%;
    }
}

@media (max-width: 991.98px) {

}

@media (max-width: 767.98px) {

}

@media (max-width: 575.98px) {

}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}
