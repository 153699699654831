@media (max-width: 1700px) {
    .pricing__card {
        padding: 30px 20px;
    }
    .pricing__title {
        font-size: 26px;
    }
    .pricing__subtitle {
        font-size: 14px;
    }
    .pricing__item {
        font-size: 14px;
    }
    .pricing__price {
        font-size: 30px;
    }
    .pricing__card .leading-\[30px\] {
        margin-bottom: 20px;
    }
}

@media (max-width: 1500px) {
    .saved-content-items-wrpr .saved-content-item.cols-6 {
        width: calc(20% - 20px);
    }
}

@media (max-width: 1199.98px) {
    .header {
        height: 64px;
        display: flex;
        justify-content: flex-end;
    }
    .header nav {
        position: fixed;
        left: -100%;
        top: 64px;
        z-index: 2;
        background-color: #111111;
        height: calc(100dvh - 64px);
        transition: 0.3s all ease-in-out;
        width: 100%;
    }
    .header nav.active {
        left: 0;
        top: 64px;
    }
    .mob_nav_btn {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        overflow: hidden;
        z-index: 999;
    }
    .mob_nav_btn span {
        width: 26px;
        height: 3px;
        background: var(--secondary-lighten-60);
        margin: 3px 0;
        transition: 0.3s all ease-in-out;
        border-radius: 3px;
    }
    .header nav.active+.mob_nav_btn span:nth-child(1){
        transform: rotate(48deg) translate(7px, 7px);
    }
    .header nav.active+.mob_nav_btn span:nth-child(2){
        transform: translateX(100%);
    }
    .header nav.active+.mob_nav_btn span:nth-child(3){
        transform: rotate(-48deg) translate(5px, -6px);
    }
    .header nav ul {
        flex-direction: column;
        width: 100%;
    }
    .header nav ul li+li {
        margin-left: 0px;
        margin-top: 15px;
    }
    .documents-card {
        width: calc(25% - 20px);
    }
    .saved-content-items-wrpr .cols-5 {
        width: calc(25% - 16px);
        margin: 8px;
    }
    
    .generated-sidebar {
        position: absolute;
        left: 72px;
        z-index: 2;
        width: calc(100vw - 72px);
    }
    .generated-sidebar > div {
        width: calc(100vw - 72px);
    }
    .generated-sidebar.generated-sidebar--closed {
        left: -100%;
    }
    .default-toolbar-wrpr,
    .default-toolbar-wrpr .default-toolbar-column,
    .default-toolbar-column,
    .editor-toolbar-column-wrpr,
    .editor-toolbar-column-wrpr .editor-toolbar-column,
    .editor-toolbar-column,
    .mob-flex-wrap {
        flex-wrap: wrap;
    }
    .default-toolbar-wrpr,
    .editor-toolbar-column-wrpr,
    .ql-toolbar.ql-snow {
        border-radius: 12px;
        padding: 10px;
    }
    .generated-content-bottom-panel-delete {
        height: initial;
        width: initial;
    }
    .editor-footer-column {
        flex-wrap: wrap;
    }
    .editor-footer-column+.editor-footer-column {
        margin-top: 5px;
    }
    .editor-footer-column button {
        margin: 0;
        padding: 5px !important;
    }
    .editor-footer-column button.generated-button {
        height: 32px;
        padding: 5px 8px !important;
    }
    .post-card-header,
    .goal-generator-card-header {
        flex-wrap: wrap;
        gap: 5px;
    }
    .default-select.multi-select.one-line .select-input textarea {
        height: auto;
    }
    .blog-post-card .generated-blog-popup,
    .with-mob-adaptive.generated-blog-popup {
        flex-direction: column;
        width: 200px;
        bottom: 10px;
        top: auto;
        transform: translateX(-50%);
    }
    .chat-bot {
        width: calc(100vw - 30px);
    }
    .share-platforms-item {
        width: calc(50% - 16px);
    }
    .gen-con-header-title {
        font-size: 12px;
        line-height: 18px;
    }
    .goal-generator-card-header {
        padding: 5px 0;
    }
    .default-select.multi-select.one-line .select-input {
        padding-left: 5px;
        padding-right: 5px;
        padding-top: 5px;
    }
    .default-select.multi-select.one-line .select-input textarea {
        font-size: 12px;
    }
    .blog-keywords-select .select-selection {
        border-radius: 30px !important;
    }
    .blog-generator-card-footer {
        position: initial;
    }
    .ql-editor {
        padding-bottom: 16px;
    }
    .gen4-layout {
        flex-direction: column;
        justify-content: flex-start;
        overflow-y: auto;
        padding-left: 16px;
        padding-right: 16px;
    }
    .gen4-main-block {
        transform: none;
        height: auto;
        max-width: calc(100vw - 32px);
    }
    .gen4-toolkit-wrapper {
        display: none;
    }
    .gen4-content {
        padding: 15px;
        overflow-x: hidden;
    }
    .gen4-layout .chat-bot-btn-wrpr {
        position: fixed;
    }
    .gen4-layout .blog-sugg-swiper {
        max-width: calc(100vw - 120px);
    }
    .gen4-footer .credit-price-block + .gen4-footer-btns-wrpr:not(.not-transform) {
        transform: none;
        margin-right: 0 !important;
        margin-left: 0 !important;
    }
    .gen4-footer .gen4-footer-btns-wrpr,
    .gen4-footer {
        flex-direction: column;
        gap: 8px;
    }
    .gen4-layout .w-50-m-8px {
        width: 100%;
    }
    .gen4-footer .gen4-footer-btns-wrpr .gen4-btn {
        margin: 0 !important;
        width: 100%;
    }
    .gen4-footer .gen4-footer-btns-wrpr > * {
        margin: 0px !important;
        width: 100%;
    }
    .generated-content-letter-card {
        flex-direction: column;
    }
    .pricing__subtitle {
        margin-bottom: 20px;
    }
    .pricing__card .leading-\[30px\] {
        margin-bottom: 10px;
    }
    .pricing__item {
        font-size: 12px;
    }
    .pricing__item:not(:last-child) {
        margin-bottom: 10px;
    }
}

@media (max-width: 991.98px) {
    .documents-card {
        width: calc(33.33% - 20px);
    }
    .saved-content-items-wrpr .cols-5 {
        width: calc(33.33% - 16px);
        margin: 8px;
    }
}

@media (max-width: 850px) {
    .saved-content-items-wrpr .cols-5 {
        width: calc(50% - 16px);
        margin: 8px;
    }
    .auth-fullscreen-wrpr .auth-content {
        display: none;
    }
    .auth-fullscreen-wrpr.split-screen .auth-form-box-fullscreen {
        max-width: none;
        padding: 50px 20px 0;
    }
    .input-custom__input {
        padding: 8px;
    }
}

@media (max-width: 767.98px) {
    .documents-card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 575.98px) {
    .documents-cards-wrpr {
        padding: 0;
        gap: 5px;
    }
    .documents-card {
        width: 100%;
        padding: 10px;
        border-radius: 2px;
    }
    /* .share-platforms-item {
        width: calc(100% - 16px);
    } */
    .want-to-create-wrpr,
    .page-section .container {
        padding-left: 10px;
        padding-right: 10px;
    }
    .product-desctription-box {
        padding: 10px 10px 0;
        border-radius: 2px;
    }
    .main-title {
        font-size: 20px;
        margin-bottom: 15px;
    }
    .categories-items,
    .search-input-form {
        margin-bottom: 15px;
    }
    .search-input-form button {
        width: 22px;
        height: 22px;
        left: 10px;
    }
    .search-input-form input {
        padding: 5px 35px;
        font-size: 12px;
    }
    .categories-items li .category-item {
        padding: 2px 8px;
        margin: 2px;
        font-size: 12px;
    }
    .assets-page-body > .p-\[20px\] {
        padding: 10px;
    }

    .assets-page-body .filters-wrap {
        flex-wrap: wrap;
        padding-left: 10px;
        padding-right: 10px;
    }

    .assets-page-body .filters-wrap > .justify-between {
        justify-content: space-between;
        width: 100%;
    }

    .sm-mr-\[0\] {
        margin-right: 0;
    }
    .sm-filter {
        gap: 8px;
        margin-top: 8px;
    }
    .sm-filter-select {
        margin: 0;
    }
    .sm-filter-collection,
    .sm-filter-wrap {
        flex-wrap: wrap;
    }
    .email-newsletter-inner-assets > .py-\[16px\].px-\[20px\] {
        padding: 10px;
    }
    .grid-cols-5 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    .sm-h-auto {
        height: auto;
    }

    .sm-bottom-filter-line {
        width: 100%;
        justify-content: space-between;
        margin-top: 8px;
    }

    .sm-bottom-filter-line > .email-sort {
        flex: 1;
    }

    .sm-filter-wrap .settings-checkbox-container.checkbox-new-words {
        width: 100%;
        order: -1;
    }

    .sm-filter-wrap .w-\[150px\] {
        flex: 1;
    }
    .leading-\[10px\] {
        line-height: 1.3;
    }
    .email-newsletter-container {
        margin: 10px;
    }
    .py-\[16px\].px-\[20px\] {
        padding: 10px;
    }
    .saved-content-items-wrpr.row-view .saved-content-item-card {
        position: relative;
    }
    .saved-content-items-wrpr.row-view .saved-content-item-bottom-content {
        margin-left: 10px;
    }
    .saved-content-items-wrpr.row-view .saved-content-item-name {
        margin-bottom: 0;
        max-width: 100%;
        margin-top: 0;
        line-height: 1.3;
        max-height: 75px;
        white-space: normal;
    }
    .saved-content-items-wrpr.row-view .card-date {
        display: none;
    }
    .saved-content-items-wrpr.row-view .saved-content-item {
        overflow: hidden;
    }
    .custom-row--5 > div {
        flex: 0 0 100%;
        max-width: 100%;
    }

    .assets-page-body .px-\[20px\] {
        padding-left: 10px;
        padding-right: 10px;
    }

    .sm-half-width.min-w-\[150px\],
    .sm-half-width {
        min-width: 0;
        flex: 1;
        margin-bottom: 5px;
    }
    .sm-full-width {
        min-width: 100%;
        width: 100%;
    }

    .sm-full-width.min-w-\[150px\] {
        min-width: 0;
        width: 100%;
        margin-right: 0;
    }
    .pagination-page-btn + .pagination-page-btn {
        display: none;
    }
    .pagination-prev-btn, .pagination-next-btn {
        height: 26px;
        padding: 0px 8px;
        font-size: 12px;
    }
    .pagination-page-btn {
        width: 26px;
        height: 26px;
        padding: 0;
        font-size: 12px;
    }
    .pagination-prev-btn.disabled, .pagination-next-btn.disabled {
        font-size: 12px;
    }
    .sm-filter-wrap + .sm-filter-wrap {
        margin-top: 5px;
    }
    .sm-half-width + .sm-half-width {
        margin-right: 0;
    }
    .sm-mt {
        margin-top: 8px;
    }
    .p-32px {
        padding: 10px;
    }
    .big-asset-swiper .swiper-arrow-button.swiper-arrow-button-next {
        right: 5px;
    }
    .big-asset-swiper .swiper-arrow-button {
        left: 5px;
    }
    .px-\[20px\] {
        padding-left: 10px;
        padding-right: 10px;
    }
    .py-\[30px\] {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .settings-component-wrapper {
        padding: 10px 0;
        flex-wrap: wrap;
    }
    .settings-component-side {
        width: 100%;
    }
    .settings-component-title {
        margin-bottom: 0;
        display: block;
    }
    .settings-component-side + .settings-component-side {
        margin-top: 10px;
    }
    .keep-it-positive-badge-item span {
        font-size: 12px;
        white-space: normal;
    }
}

@media (min-width: 1700px) {
    .documents-card {
        width: calc(20% - 20px);
    }
}
