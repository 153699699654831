.auth-classic-wrpr {
    min-height: 100dvh;
    overflow-y: auto;
    padding: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(../../img/auth-bg.png);
    background-size: cover;
    background-position: center;
}
.auth-form-box-center {
    border-radius: 16px;
    background: #232323;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20);
    padding: 48px;
    max-width: 496px;
    width: 100%;
}
.auth-form h3 {
    color: #FFF;
    font-size: 32px;
    font-weight: 800;
    line-height: normal;
}
.auth-dont-have p {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
}
.auth-dont-have a {
    color: #EA8207;
    font-size: 14px;
    font-weight: 500;
    line-height: normal;
    /* margin-left: 8px; */
}
.auth-form .input-custom__label {
    color: #FFF;
    font-size: 14px;
}
.auth-form .input-custom__input {
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: #323232;
}
.auth-form .submit-btn {
    color: rgba(0, 0, 0, 0.75);
    font-size: 14px;
    font-weight: 700;
    padding: 11px 10px;
    box-shadow: 4px 4px 16px 0px rgba(255, 129, 20, 0.25);
}
.info-gray-box {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.02);
}
.info-gray-box svg {
    margin-right: 10px;
}
.info-gray-box p {
    flex: 1;
    color: var(--secondary-lighten-60);
    font-size: 12px;
    font-weight: 300;
    line-height: normal;
}
.info-gray-box p a {
    color: #FF8114;
}
.social-links-items {
    display: flex;
    align-items: center;
    justify-content: center;
}
.social-links-items li+li {
    margin-left: 16px;
}
.social-links-items li a {
    border-radius: 5px;
    border: 0.659px solid rgba(255, 255, 255, 0.12);
    min-height: 48px;
    padding: 0 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.auth-dont-have a:hover,
.info-gray-box p a:hover,
.social-links-items li a:hover {
    opacity: 0.7;
}
.auth-fullscreen-wrpr {
    display: flex;
    /* align-items: flex-start; */
    min-height: 100dvh;
}
.auth-fullscreen-wrpr .auth-content {
    background: url(../../img/auth-bg.png);
    background-size: cover;
    background-position: center;
    width: 100%;
    min-height: 100dvh;
    display: flex;
    flex-direction: column;
    padding: 48px 106px;
}
.auth-form-box-fullscreen {
    max-width: 496px;
    width: 100%;
    min-height: 100dvh;
    background: #232323;
    border: 1px solid rgba(255, 255, 255, 0.12);
    padding: 48px;
    display: flex;
    flex-direction: column;
}
.auth-form-box-fullscreen .auth-form {
    margin: auto;
}
.auth-content-inner {
    max-width: 930px;
    width: 100%;
    margin: auto;
}
.auth-content-inner h1 {
    color: #FFF;
    font-size: 48px;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 16px;
}
.auth-content-inner p {
    color: rgba(255, 255, 255, 0.50);
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
}
.avatars-list {
    display: flex;
    align-items: center;
}
.avatars-list li {
    border: 8px solid #141415;
    border-radius: 50%;
    overflow: hidden;
    width: 64px;
    height: 64px;
    min-width: 64px;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.avatars-list li+li {
    margin-left: -22px;
}
.avatars-list li img {
    object-fit: cover;
    width: 100%;
}
.auth-content-inner .logo {
    margin-bottom: 16px;
    max-width: 609px;
}
.auth-fullscreen-wrpr.split-screen .auth-content,
.auth-fullscreen-wrpr.split-screen .auth-form-box-fullscreen {
    max-width: 50vw;
}
.auth-fullscreen-wrpr.split-screen .auth-form-box-fullscreen .auth-form {
    margin: auto auto auto 0;
    max-width: 400px;
}
.auth-modern-wrpr {
    background: #151516;
    padding: 140px 248px;
}
.auth-modern-wrpr .auth-fullscreen-wrpr {
    border-radius: 32px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20);
    overflow: hidden;
}
.auth-modern-wrpr .auth-fullscreen-wrpr,
.auth-modern-wrpr .auth-fullscreen-wrpr .auth-content,
.auth-modern-wrpr .auth-form-box-fullscreen {
    min-height: calc(100dvh - 286px);
}
.auth-modern-wrpr .auth-fullscreen-wrpr .auth-content {
    padding: 48px 64px;
}
.auth-modern-wrpr .auth-form-box-fullscreen {
    border-right: 0;
    border-top: 0;
    border-bottom: 0;
}
.in-input-right {
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}
.password-input-view {
    display: flex;
    align-items: center;
    justify-content: center;
}
.password-input-view svg path {
    transition: 0.15s all ease-in-out;
}
.password-input-view:hover svg path {
    fill-opacity: 0.5;
}
.hover-opacity-07:hover {
    opacity: 0.7;
}
.code-input-wrpr {
    display: flex;
}
.code-input-wrpr input {
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    height: 72px;
}
.code-input-wrpr input+input {
    margin-left: 10px;
}