.dropdown {
    
}
.dropdown__body {
    display: none;
    position: fixed;    
    border: 1px solid #262626;
    background: #1B1B1B;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20);
    color: var(--secondary-lighten-60, #A7A7A7);
    overflow: auto;
    margin-top: 15px;
    border-radius: 8px;
    z-index: 9999;
}
.dropdown__body.is-open {
    display: block;
    white-space: nowrap;
}
.secondary-lighten-60 {
    color: var(--secondary-lighten-60);
}
.hover-primary-color:hover {
    color: var(--primary);
}
.btn-action {
    padding: 10px;
}
.sidebar-is-open .sidebar-fixed {
    padding-left: 280px;
}
.sidebar-is-open .header {
    width: calc(100% - 280px);
    left: 280px;
}
.header-fixed {
    padding-top: var(--header-fixed-padding);
}
.header-border {
   border-bottom: 1px solid #262626;
}
/* sidebar */
.sidebar-nav {
    position: fixed;
    height: 100%;
    overflow: auto;
    width: 280px;
    left: -280px;
    top: 0;
    z-index: 200;
    background-color: #161616;
    color:  var(--secondary-lighten-60, #A7A7A7);
    transition: .2s;
}
.sidebar-nav::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.sidebar-is-open .sidebar-nav {
    left: 0;
}
.sidebar-logo {
    flex: auto;
}
.sidebar-nav__head {
    position: sticky;
    top: 0;
    background-color: #161616;
    padding: 10px;
    border-bottom: 1px solid var(--primary-darken-70);
}
.sidebar-nav__info{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}
.user-actins-list {
    min-width: 260px;
}
.user-avatar {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    flex: 0 0 24px;
    border-radius: 230px;
    border: 2px solid var(--secondary-lighten-60, #A7A7A7);
    background: lightgray 50% / cover no-repeat;
    font-size: 16px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    color: var(--primary-darken-80, #331A04);
}
.user-actins-list {
    padding: 10px 0;
    border-bottom: 1px solid #262626;
    border-top: 1px solid #262626;
}
.user-actions-drop__body .btn{
    color: var(--secondary-lighten-60, #A7A7A7);
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}
.user-avatar.whidtout-photo {
    border-color: var(--primary);
    background: var(--primary, #FF8114);
}
.user-avatar > * {
    width: 100%;
}

.sidebar-nav__info .user-actions-drop {
    margin-left: 20px;
}

.sidebar-nav__item {
    padding: 20px 10px;
}

.sidebar-nav__item + .sidebar-nav__item {
    border-top: 1px solid #262626;
}

.change-user-name {
    display: block;
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 13px;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.change-user-drop-info {
    flex: auto;
    overflow: hidden;
    padding: 0 10px;
}
.change-user-drop-btn {
    text-align: start;    
    width: 100%;
}

.change-user-info-list {
    display: flex;
    align-items: center;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 8px;
    color: var(--secondary-lighten-60, #A7A7A7);
    margin-top: 5px;
}

.change-user-info-list li {
    display: flex;
    align-items: center;
}

.drop-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.change-user-info-list li + li::before {
    content: 'â€¢';
    display: block;
    margin-left: 3px;
    margin-right: 3px;
}

.dropdown-open .drop-icon {
    transform: rotate(180deg);
}

.change-user-drop-btn {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid #262626;
}

.change-user-drop__list {
    padding: 10px 0;
    border-top: 1px solid #262626;
    border-bottom: 1px solid #262626;
}
.change-user-drop__list .change-user-drop-btn {
    border:0;
    padding: 10px 20px;
}

.change-user-drop__title {
    padding: 10px 20px;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.btn-icon {
    display: flex;
    align-items: center;
    justify-content: center;
}

.create-team-btn {
    color: var(--secondary-lighten-60, #A7A7A7);
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    padding: 10px 20px;
}

.change-user-drop__list .change-user-drop-btn{
    color: var(--secondary-lighten-60, #A7A7A7);
}   

.change-user-drop__list .change-user-drop-btn.selected ,
.change-user-drop__list .change-user-drop-btn:hover {
    color: white;
}
.change-user-drop-btn.selected {
    pointer-events: none;
}
.change-user-drop__list {
    min-width: 260px;
}

.sidebar-nav__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding-left: 10px;
}
.sidebar-nav__title + .btn--primary-shadow {
    margin-top: 10px;
} 
.sidebar-nav__list-item {
    margin-top: 10px;
}
.sidebar-nav__list-icon {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}
.sidebar-nav__list-link {
    display: flex;
    align-items: center;
    color: var(--secondary-lighten-60, #A7A7A7);
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    border-radius: 8px;
}

.sidebar-nav__list-link:hover {
    color: white;
}

.sidebar-nav__list-link.active {
    color: white;
    background: rgba(255, 255, 255, 0.10);
}

.btn--primary-shadow {
    padding: 10px;
    border-radius: 8px;
    border: 1px solid var(--primary, #ff8214);
    background: var(--primary, #FF8114);
    box-shadow: 4px 4px 16px 0px rgba(255, 129, 20, 0.25);
}

.btn--primary-shadow:hover {
    box-shadow: none;
}

.sidebar-nav__footer {
    position: sticky;
    bottom: 0;
    background-color: #161616;
    border-top: 1px solid #262626;
}
.user-action-info {
    display: flex;
    align-items: center;
    padding: 20px;
}
.user-action-info__avatar {
    width: 48px;
    height: 48px;
    flex:0 0 48px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    border-radius: 50%;
}
.user-action-info__text .user-action-info__name {
    color: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.user-action-info__text .change-user-info-list{
    font-size: 12px;
}

.change-user-info-list .btn-upgrade {
    border-radius: 4px;
    color: var(--primary-darken-80, #331A04);
    font-size: 8px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 3px 8px;
}

.change-user-info-list li + li::before {
    margin-left: 10px;
    margin-right: 10px;
}

.generated-content-footer {
    border-radius: 0px 0px 24px 24px;
    background: rgba(22, 22, 22, 0.98);
    position: sticky;
    bottom: 0;
    
}

.generated-content-footer__item {
    padding-left: 20px;
    padding-right: 20px;
}
.hr-secodary {
    border: 0;
    border-bottom: 1px solid #262626;
}
.buttons-group {
    display: flex;
    flex-wrap: wrap;
    margin-left: -8px;
}
.buttons-group > * {
    margin-left: 8px;
    margin-bottom: 8px;
}
@supports (backdrop-filter: blur(15px)) {
    .generated-content-footer {
        background: rgba(22, 22, 22, 0.80);
        backdrop-filter: blur(15px);
    }
}

.generated-content-footer__labels {
    display: flex;
    flex-wrap: wrap;
    margin-right: -20px;
    padding-top: 12px;
}

.generated-content-footer__labels li {
    display: flex;
    align-items: center;
    color: #9D9D9D;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-right: 20px;
    margin-bottom: 12px;
}
.generated-content-footer__labels li svg,
.generated-content-footer__labels li img {
    margin-right: 10px;
}
.generated-content-footer__labels li.--color-primary {
    color: var(--primary);
}

.custom-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
}
/* .custom-checkbox.disabled {
    pointer-events: none;
} */
.custom-checkbox-fake {
    display: block;
    width: 20px;
    height: 20px;
    flex:0 0 20px;
    border-radius: 4px;
    border: 2px solid var(--secondary-lighten-60);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.custom-checkbox input:checked + .custom-checkbox-fake{
    background-image: url('data:image/svg+xml,<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="done_FILL1_wght700_GRAD200_opsz48 1"><path id="Vector" d="M7.87427 14.9372C7.74927 14.9372 7.62774 14.9129 7.50968 14.8643C7.39163 14.8157 7.29788 14.7428 7.22843 14.6455L3.4576 10.8955C3.27705 10.715 3.18677 10.4858 3.18677 10.208C3.18677 9.93026 3.27705 9.7011 3.4576 9.52054C3.63816 9.33999 3.86385 9.24971 4.13468 9.24971C4.40552 9.24971 4.63816 9.33999 4.8326 9.52054L7.87427 12.583L15.1659 5.29137C15.3604 5.11082 15.5895 5.01707 15.8534 5.01012C16.1173 5.00318 16.3395 5.09693 16.5201 5.29137C16.7145 5.48582 16.8118 5.71846 16.8118 5.98929C16.8118 6.26012 16.7145 6.48582 16.5201 6.66637L8.54093 14.6455C8.4576 14.7428 8.35691 14.8157 8.23885 14.8643C8.1208 14.9129 7.99927 14.9372 7.87427 14.9372Z" fill="%23EA8207"/></g></svg>');
}
.custom-checkbox.disabled input:checked + .custom-checkbox-fake {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none"><path d="M7.87427 14.9372C7.74927 14.9372 7.62774 14.9129 7.50968 14.8643C7.39163 14.8157 7.29788 14.7428 7.22843 14.6455L3.4576 10.8955C3.27705 10.715 3.18677 10.4858 3.18677 10.208C3.18677 9.93026 3.27705 9.7011 3.4576 9.52054C3.63816 9.33999 3.86385 9.24971 4.13468 9.24971C4.40552 9.24971 4.63816 9.33999 4.8326 9.52054L7.87427 12.583L15.1659 5.29137C15.3604 5.11082 15.5895 5.01707 15.8534 5.01012C16.1173 5.00318 16.3395 5.09693 16.5201 5.29137C16.7145 5.48582 16.8118 5.71846 16.8118 5.98929C16.8118 6.26012 16.7145 6.48582 16.5201 6.66637L8.54093 14.6455C8.4576 14.7428 8.35691 14.8157 8.23885 14.8643C8.1208 14.9129 7.99927 14.9372 7.87427 14.9372Z" fill="%231B1B1B"/></svg>');
}
.custom-checkbox-text {
    color: var(--secondary-lighten-60, #A7A7A7);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 10px;
}

.custom-checkbox.disabled .custom-checkbox-text{
    color: rgba(255, 255, 255, 0.25);
}
.custom-checkbox.disabled .custom-checkbox-fake {
    border-color: rgba(255, 255, 255, 0.25);
}

.page-section {
    padding: 20px 0;
    width: 100%;
    height: calc(100vh - 67px);
    overflow: auto;
}
.page-section::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.page-section .container {
    max-width: none;
    padding-left: 20px;
    padding-right: 20px;
}
.sidebar-is-open .wrapper.sidebar-fixed .content {
    width: auto;
}
.project-card {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: var(--secondary-lighten-60, #A7A7A7);
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.10);
    min-height: 300px;
    padding: 25px 20px;
    height: 100%;
    transition: all .2s;
}
.add-new-project {
    color: var(--primary, #FF8114);
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.project-card:hover {
    border-color: var(--primary, #FF8114);
    box-shadow: 4px 4px 16px 0px rgba(255, 129, 20, 0.25);
}
.project-card__title {
    color: var(--primary, #FF8114);
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.project-card__desc {
    margin-bottom: 10px;
}
.project-card__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: auto;
}
.project-card__footer .btn {
    color: currentColor;
}
.project-card__footer .btn:hover {
    color: var(--primary, #FF8114);
}
.search-project {
    display: flex;
    align-items: center;
    border-top: 1px solid  #393939;
    border-bottom: 1px solid  #393939;
}

.search-project .input-custom__wrap-input {
    flex: auto;
}
.search-project .input-custom__input{
    background-color: transparent;
    border: 0
}
.project-filters {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.project-filters__actions {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -10px;
}

.project-filters__actions > * {
    min-width: 212px;
    margin-bottom: 12px;
    margin-right: 10px;
}

.project-filters__title {
    color: var(--secondary-lighten-60, #A7A7A7);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-right: 10px;
    margin-bottom: 12px;
}

.g-20 {
    --bs-gutter-x: 20px;
    --bs-gutter-y: 20px;
}

.page-title {
    color: #FFF;
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: normal;
    margin-bottom: 8px;
}
.page-desc {
    color: var(--secondary-lighten-60, #A7A7A7);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}


.upload-files {
    cursor: pointer;
}
.upload-files__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #262626;
    background-color: #1B1B1B;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.50);
    min-height: 48px;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.upload-files__title {
    padding-left: 12px;
}
.upload-files__icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-left: 1px solid #262626;
    align-self: stretch;
    padding: 0 10px;
    font-size: 12px;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.page-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    border-top:1px solid #262626;
    padding-left: 20px;
    padding-right: 20px;
    margin-top: 20px;
    margin-left: -20px;
    margin-right: -20px;
    position: sticky;
    bottom: 0;
    background-color: var(--darck-color);
    padding-bottom: 20px;
}