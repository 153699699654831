.w-auto {
    width: auto !important;
}

.pointer {
    cursor: pointer !important;
}

.relative {
    position: relative;
}
.ck.ck-toolbar__items {
    gap: 5px;
}

.not-allowed {
    cursor: not-allowed !important;
}

.toolbar-one-line-30, .toolbar-one-line-50 {
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
    padding-right: 10px;
}

.toolbar-one-line-30 {
    width: 33%;
}
.toolbar-one-line-50 {
    width: 50%;
}

.toolbar-one-line-50:nth-last-child(1), .toolbar-one-line-30:nth-last-child(1) {
    padding: 0;
}

.btn-box-option {
    width: 36px;
    height: 36px;
    margin-right: 10px; 
    padding: 10px 8px;
    border-radius: 8px;
    border: 1px solid #262626;
    background: #1B1B1B;
}


.search-input {
    position: relative;
}

.search-container {
    top: 100%;
    position: absolute;
    width: 100%;
    border: 1px solid #262626;
    background: #1B1B1B;
    border-radius: 0 0 8px 8px;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20);
    overflow: hidden;
    overflow-y: auto;
    max-height: 290px;
}
.search-input-form:not(.autofill-dropdown-open) .search-container {
    opacity: 0;
    visibility: hidden;
}

.search-container li {
    padding: 8px;
    cursor: pointer;
    font-size: 12px;
    transition: 0.3s all ease-in-out;
}

.highlight-char {
    color: var(--primary);
}
.search-container li:hover {
    background: rgba(255, 255, 255, 0.10);
}

.search-title {
    font-size: 14px;
}

.search-desc {
    margin-top: 3px;
    max-width: 150px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 11px;
    color: var(--secondary-lighten-60);
}

.gen-toolkit-wrapper {
    width: 280px;
    border-left: 1px solid #262626;
}

.gen-toolkit-header {
    padding: 20px;
    height: 64px;
    display: flex;
    align-items: center;

    border-bottom: 1px solid #262626;

}

.gen-toolkit-header__title {
    color: var(--secondary-lighten-60, var(--secondary-lighten-60));
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

}

.gen-toolkit-header__dropdown {
    display: flex;
    align-items: center;

    font-size: 16px;
    font-weight: 600;
}

.get-toolkit-header__actions {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.get-toolkit-header__actions li:nth-child(2) {
    margin: 0 10px;
}

.get-toolkit-content {
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
}

/*.blog-toolkit-wrapper {*/
/*    width: 300px;*/
/*    border-right: 1px solid #262626;*/
/*}*/

.blog-toolkit-header {
    display: flex;
    padding: 14px 20px;
    min-height: 55px;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: var(--secondary-lighten-60, var(--secondary-lighten-60));

    border-bottom: 1px solid #262626;
}

.blog-toolkit-header__actions {
    display: flex;
    align-items: center;

    margin-left: auto;
}

.blog-toolkit-header__actions svg:last-child {
    margin-left: 10px;
}

.blog-toolkit-header__toolbar {
    display: flex;
    padding: 7px 20px;

    color: var(--secondary-lighten-60, var(--secondary-lighten-60));
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;

    border-bottom: 1px solid #262626;
}

.blog-toolkit-header__toolbar a {
    margin-right: auto;
    cursor: pointer;
}

.blog-toolkit-header__toolbar a.active svg path {
    fill: var(--primary);
}

.blog-toolkit-header__toolbar span {
    cursor: pointer;
}

.blog-toolkit-header__toolbar span:last-child {
    margin-left: 20px;
}

.blog-toolkit-header__toolbar span.active {
    color: var(--primary);
}

.blog-toolkit-content {
    display: flex;
    flex-direction: column;
}

.blog-toolkit-content__toolbar {
    display: flex;
    margin-left: auto;
    padding: 12px 20px;
}

.blog-toolkit-content__toolbar a:last-child {
    margin-left: 16px;
}

.blog-toolkit-content__toolbar a.fill-primary svg path {
    fill: var(--primary);
    transition: fill 0.5s ease;
}

.blog-toolkit-content__container {
    padding: 16px;
    /*height: calc(100dvh - 133px);*/
    height: calc(100dvh - 200px);
    overflow: auto;
}

.blog-toolkit-content__container::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.blog-toolkit-content__block {
    display: flex;
    flex-direction: column;
    /*margin-bottom: 16px;*/
    position: relative;
}


.btc__block-head {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 10px;
}

.btc__block-head-title {
    display: flex;
    align-items: center;

    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
}

.btc__block-head-title svg {
    margin-right: 8px;
}

.btc__block-head-actions {
    display: flex;
    align-items: center;

    margin-left: auto;
}

.head-action {
    transition: all 0.3s ease;
    transform: rotate(180deg);
}

.head-action.rotate180 {
    transform: rotate(0deg);
}

.btc__block-head-actions .head-action:not(:last-child) {
    cursor: pointer;
    margin-right: 10px;
}

.btc__block-content {
    display: flex;
    flex-direction: column;
    margin-top: 10px;

}

.btc__block-item {
    display: flex;
    height: 30px;
    /* min-width: 170px; */
    padding: 6px 12px;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 0;
    margin-bottom: 8px;
    cursor: pointer;

    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.10);
}

.btc__block-item.grey {
    background: #4B4B4B;
}

.btc__item-text {
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.btc__block-load {
    display: flex;
    justify-content: center;
}

/* gen box */

.content-box-header {
    padding: 14px 20px 14px 20px; 
    border-radius: 24px 24px 0px 0px;
    border: 1px solid #262626;
    background: #1B1B1B;
}

.content-box-subtitle {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: -0.12px;
    text-transform: uppercase;
    margin-top: 8px;
    color: rgba(255, 255, 255, 0.6);
}

.content-box-header a {
    margin-left: auto;
}

.content-box-body {
    border: 1px solid #262626;
    border-top: none;
    background: #161616;
    padding: 24px 20px;
    height: calc(100dvh - 274px);
    overflow-y: auto;
}

.product-description__item {
    color: rgba(255, 255, 255, 0.25);
    transition: all 0.5s ease;
}
.product-description__item.active {
    border: 1px solid #262626;
    background: #1B1B1B;
    color: white;
}

.product-description__group {
    min-height: 400px;
}

.product-description__body {
    height: 400px;
    display: flex;
    flex-direction: column;
}

.product-description__body .input-custom {
    display: flex;
    flex: 1;
}

.product-description__item.active .product-description__item-text {
    transition: all 0.5s ease;
}

.product-description__textarea::-webkit-scrollbar, 
.content-box-body::-webkit-scrollbar {
    width: 1px;
}

.new-words-dropdown::-webkit-scrollbar,
.kit-details-page-content::-webkit-scrollbar,
.want-to-create-wrpr::-webkit-scrollbar {
    width: 3px;
}
  
.product-description__textarea::-webkit-scrollbar-track, 
.content-box-body::-webkit-scrollbar-track,
.new-words-dropdown::-webkit-scrollbar-track,
.kit-details-page-content::-webkit-scrollbar-track,
.want-to-create-wrpr::-webkit-scrollbar-track {
    background: #1B1B1B;
}

.product-description__textarea::-webkit-scrollbar-thumb, 
.content-box-body::-webkit-scrollbar-thumb,
.new-words-dropdown::-webkit-scrollbar-thumb,
.kit-details-page-content::-webkit-scrollbar-thumb,
.want-to-create-wrpr::-webkit-scrollbar-thumb {
    background-color: var(--primary);
    border-radius: 20px;
    border: 2px solid var(--primary);
}
/* .product-description-block {
    transform: scaleX(-1);
} */

.box-product-container {
    /* transform: scaleX(-1); */
    /* height: calc(100dvh - 413px);
    overflow-y: auto; */
}

.product-description__group.empty-group {
    align-items: center;
    justify-content: center;
}
.sidebars-wrpr.toolbar-sidebar-close {
    transform: translateX(-100%);
    opacity: 0;
    visibility: hidden;
    width: 0;
}
.sidebars-wrpr.toolbar-sidebar-close+.content-box-wrpr {
    width: 100vw;
}

.accordion {
    overflow: hidden;
    transition: height 0.5s ease;
}
  
.accordion-open {
    display: block;
}

.blog-toolkit-content__block-collapse {
    display: flex;
    padding: 15px;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #262626;
    background: #232323;
    margin-bottom: 10px;

    cursor: pointer;
}
.blog-toolkit-content__block-collapse span, .blog-toolkit-content__block-collapse svg path {
    transition: all 0.5s ease;
}
.blog-toolkit-content__block-collapse:hover span, .blog-toolkit-content__block-collapse:hover svg path {
    color: #fff;
    fill-opacity: 1;
}

.toolkit-block-text-title {
    color: rgba(255, 255, 255, 0.50);
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.block-collapse-arrow {
    cursor: pointer;
    margin-left: auto;
}

.toolkit-oneblock-header {
    display: flex;
    padding: 10px 20px;
    justify-content: center;
    align-items: center;
    align-self: stretch;
    border-bottom: 1px solid #262626;
}

/* .toolkit-oneblock-header svg {
    margin-right: 20px;
} */

.toolkit-oneblock-header__back {
    position: absolute;
    top: 50%;
    left: 10px;
    transform: rotate(180deg) translate(0, 50%);
}


.btc__block-item-website {
    display: flex;
    padding: 5px 6px 5px 10px;
    cursor: pointer;
    /* justify-content: space-between; */
    align-items: center;
    align-self: stretch;
    margin-bottom: 10px;
    /* flex-wrap: wrap; */
    /* gap: 6px; */

    border-radius: 58px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.10);
}

.toolkit-item-website-desc {
    display: flex;
    height: 30px;
    padding: 8px 12px;
    margin-right: 10px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 32px;
    white-space: nowrap;
    background: var(--secondary-lighten-10, #393939);

    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.toolkit-item-website-name {
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    text-overflow: ellipsis;
    overflow: hidden;

    margin-right: 20px;
}

.toolkit-item-website-action {
    margin-left: auto;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(0, -50%);
}

.search-input-form input.toolkit-search-input {
    padding: 3px 15px;
    font-size: 12px;
}

.btc__block-load svg path, .head-action svg path, .link-hover-svg svg path, .btc__block-item svg path, .toolkit-oneblock-header__back svg path,
.btc__block-item-website svg path {
    transition: all 0.5s ease;
}

.btc__block-load:not(.disable-fill):hover svg path, .head-action:hover svg path, .link-hover-svg:hover svg path, .btc__block-item:hover svg path, 
.toolkit-oneblock-header__back:hover svg path, .btc__block-item-website:hover svg path {
    fill: #fff;
    fill-opacity: 1;
}



.btc__block-item span, .hover-text, .btc__block-item-website span {
    transition: color 0.5s ease;
}

.btc__block-item:hover span, .hover-text:hover, .btc__block-item-website:hover span {
    color: #fff;
}

.checkbox-block-toolbar:has(.parent-off-flex-wrap) {
    flex-wrap: nowrap !important;
}

.multi-select .select-selection .bbb>.select-multi-value.orange {
    border-radius: 20px;
    background: #EA8207;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    max-width: max-content;
    margin-right: 8px;
    margin-bottom: 8px;
    flex-direction: row-reverse;
}

.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value+.select-multi-value.orange svg {
    height: 21px;
    width: 21px;
    margin-right: 5px;
}

.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value+.select-multi-value.orange {
    padding: 0;
}

.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value.orange {
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    padding: 0;
}

.keywords-counter {
    position: absolute;
    top: -29px;
    right: 5px;
    z-index: 1;
}

.sidebar-nav__list-link.active {
    border-radius: 8px;
    border: 1px solid #262626;
    background: #1B1B1B;
    color: var(--secondary-lighten-60);
}
.sidebar-nav__list-link.active:hover {
    color: #fff;
}

.sidebar-nav__title {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0.88px;

    color: rgb(255 255 255 / 30%);
}

.btn--primary-shadow {
    border-radius: 360px;
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;

    color: #331A04;
    font-size: 14px;
    font-weight: 500;
}

.sidebar-divider {
    width: 228px;
    height: 1px;
    background: #262626;
    margin: auto;
}

.sidebar-nav__item + .sidebar-nav__item, .sidebar-nav__head {
    border: none;
}

.sidebar-nav__list-link:hover svg path {
    fill: #fff;
}
.sidebar-nav__list-link svg path {
    transition: all 0.5s ease;
}

.sidebar-nav__list-icon {
    margin-right: 0;
    padding: 9px 11px 9px 10px;
}

.sidebar-nav__list-item {
    margin-top: 4px;
}

.sidebar-nav__title + .btn--primary-shadow {
    margin: 8px 0 4px 0;
    padding: 7px 20px;
    box-shadow: none;
}

.user-avatar-header {
    width: 30px;
    height: 30px;
}

.user-avatar {
    width: 26px;
    height: 26px;
    flex: 0 0 26px;
    border: none;
}

.change-user-name {
    color: #fff;
    font-size: 13px;
    font-weight: 400;
    line-height: 14px;
}

.change-user-info-list {
    font-size: 11px;
}

.change-user-info-list li + li::before {
    margin: 0 4px;
}

.sidebar-nav__item {
    padding: 17px 10px;
}

.sidebar-nav__list-link {
    font-weight: 400;
    border: 1px solid transparent;
}

.change-user-info-list img {
    width: 10px;
    height: 10px;
}

.sidebar-nav__info .user-actions-drop {
    margin-left: 16px;
}
.generated-content-message__icon-wrapper{
    width: 70px;
    height: 70px;
    border-radius: 16px;
    background: #151515;
    display: flex;
    align-items: center;
    justify-content: center;
}
.generated-content-message__icon{
    height: 40px;
    width: 40px;
    opacity: 0.6;
}
.generated-content-message__title{
    color: #FFF;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px; 
}
.generated-content-message__subtitle{
    color: var(--secondary-lighten-60);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px; 
    text-align: center;
    opacity: 0.5;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 6px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
0% {
    transform: rotate(0deg);
}
100% {
    transform: rotate(360deg);
}
}

/* new 2 variant toolbar */
.toolbar-body-nav-container {
    position: relative;
    display: flex;
    overflow: auto;
}

.toolbar-body-nav-container::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.toolbar-sidebar-body {
    padding: 0px !important;
    border-radius: unset !important;
}

.toolbar-body-nav {
    width: 100%;
    justify-content: space-between;
    display: flex;
    position: relative;
    padding: 0 16px;
}

.toolbar-body-nav::after, .toolbar-body-nav::before, .input-custom-wrapper::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #1F1F1F;
    position: absolute;
    bottom: 0;
    left: 0;
}
.toolbar-body-nav::before {
    bottom: unset;
    top: 0;
}

.toolbar-body-nav li {
    min-width: 69px;
    padding: 16px 7px;
    color: var(--secondary-lighten-60);
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    opacity: 0.9;
    transition: color 0.2s ease;
    white-space: nowrap;
    cursor: pointer;
}
.toolbar-body-nav li.activeTab {
    color: #FFFFFF;
}

.toolbar-body-nav li:first-child {
    padding-left: 7px;
}
.toolbar-body-nav li:last-child {
    padding-right: 7px;
}

.tabs-underline {
    height: 2px;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #FF8114;;
    transition: all 0.2s ease;
}

.input-custom-wrapper {
    padding: 20px 16px;
    position: relative;
}
.input-custom-wrapper.no-after {
    padding: 20px 16px 0;
}

.input-custom-wrapper.no-after::after {
    display: none;
}

/* TODO trash */
.input-suggestion {
    display: flex;
    align-items: center;
    margin-left: auto;

    color: var(--secondary-lighten-60);
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}

.input-suggestion svg {
    margin-right: 6px;
}

/* TODO trash */
.toolbar-body-suggestions {
    padding: 16px 0 0;
}
/* TODO trash */
.toolbar-body-header-container {
    position: relative;
    margin-bottom: 12px;
}
/* TODO trash */
.toolbar-body-header-container, .toolbar-body-header {
    display: flex;
    align-items: center;
}
/* TODO trash */
.toolbar-body-header {
    color: #FF8114;
    font-size: 12px;
    font-weight: 400;
}
/* TODO trash */
.toolbar-body-header svg {
    margin-right: 6px;
}

.toolbar-body-reload {
    margin-left: auto;
    cursor: pointer;
}

.input-box-list-item {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    color: var(--secondary-lighten-60);
    font-size: 12px;
    font-weight: 400;
    line-height: 17px; /* 141.667% */
}

/* TODO trash */
.input-box-list-item:last-child {
    margin-bottom: 0;
}
/* TODO trash */
.input-box-list-item svg {
    margin-right: 12px;
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    /*padding: 2.25px;*/
}

.textarea {
    padding: 16px 8px 8px 14px;
}

.suggestion-options {
    display: flex;
    align-items: center;
    overflow: auto;
    position: relative;
}

.suggestion-options::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.toolbar-shadow-ul {
    position: relative;
    width: 100%;
}
.toolbar-shadow-ul::after {
    content: "";
    position: absolute;
    width: 16px;
    height: 50px;
    top: -13px;
    right: 0;
    z-index: 1;
    background: linear-gradient(270deg, #161616 0%, rgba(22, 22, 22, 0.00) 100%);
}

/* TODO trash */
.suggestion-options-item {
    cursor: pointer;
    display: flex;
    height: 24px;
    padding: 6px 6px 6px 10px;
    margin-right: 4px;
    align-items: center;
    border-radius: 24px;
    background: #202020;
    color: rgba(255, 255, 255, 0.50);

    font-size: 12px;
    font-weight: 500;

    transition: all 0.2s ease;
}
.suggestion-options-item:hover {
    color: #FFFFFF;
}

.suggestion-options-item:hover svg path, .suggestion-options-item:hover svg  {
    fill: #FFFFFF !important;
    fill-opacity: 1;
}

.suggestion-options-item.active {
    background: #FF8114;
    color: #331A04;
}

.suggestion-options-item svg {
    width: 16px;
    height: 16px;
    margin-left: 4px;
    transition: all 0.2s ease;
    transform: rotate(180deg);
}
.suggestion-options-item svg path {
    transition: all 0.2s ease;
    fill: var(--secondary-lighten-60);
}
.suggestion-options-item.active svg path {
    fill: #331A04;
}

.suggestion-options-item.active svg {
    transform: rotate(0deg);
}

/* TODO trash */

.suggestion-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    align-items: center;
}

.suggestion-list-item, .keywords-select-item {
    margin-bottom: 8px;
    margin-right: 6px;
}

.keywords-container {
    display: flex;
    flex-wrap: wrap;
    margin-top: 12px;
}
.keywords-select-item {
    display: flex;
    padding: 5px 6px 5px 10px;
    align-items: center;
    border-radius: 24px;
    border: 1px solid #383838;
    background: #202020;

    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-weight: 500;
}
.keywords-select-item svg {
    margin-left: 6px;
}
.keywords-suggestion-item {
    display: flex;
    height: 26px;
    padding: 5px 10px;
    align-items: center;
    border-radius: 24px;
    background: #202020;
    margin: 0 8px 8px 0;

    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-weight: 500;
}

.toolbar-sidebar-header {
    background: #161616;
    /*min-height: 64px;*/
    padding: 14px 16px;
    color: #393939;
    margin-bottom: 0 !important;
    min-height: unset !important;
}

.toolbar-sidebar-header p {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    opacity: 0.4;
}

.toolbar-sidebar-header h3 {
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
}

.toolbar-header-buttons {
    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;
}

.back-arrow-link {
    border-radius: 8px;
    opacity: 0.4;
}


/* toolkit my assets new */

.toolkit-block-header {
    display: flex;
    align-items: center;
}
.toolkit-block-title {
    color: var(--secondary-lighten-60);
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
}
.toolkit-block-counter {
    margin-left: auto;
    display: flex;
    width: 18px;
    height: 18px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 300px;
    background: #FF8114;
}
.toolkit-block-counter span {
    overflow: hidden;
    color: #331A04;
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 600;
    line-height: 17px;
}

.toolkit-block-footer {
    display: flex;
    align-items: center;
    justify-content: center;
}

.btc__block-item-big {
    display: flex;
    padding: 14px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    margin: 8px 0;

    border-radius: 12px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: rgba(255, 255, 255, 0.05);
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.10);

    transition: all 0.2s ease;
}

.btc__block-item-big:first-child {
    margin-top: 0;
}

.btc__block-item-big-paragraph {
    height: 53px;
    align-self: stretch;

    overflow: hidden;
    color: rgba(255, 255, 255, 0.50);
    text-overflow: ellipsis;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 14px;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;

    transition: height 0.5s ease, color 0.2s ease;
}


.btc__block-item-big-title {
    overflow: hidden;
    color: rgba(255, 255, 255, 0.50);
    text-overflow: ellipsis;
    font-size: 14px;
    font-weight: 500;
    line-height: 17px;
    margin-bottom: 14px;
}

.btc__block-item-big-actions-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.btc__block-item-big-actions {
    display: flex;
    align-items: center;
    margin-left: auto;
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-weight: 500;

    transition: color 0.2s ease;
}

.btc__block-item-big-actions-divider {
    width: 1px;
    height: 18px;
    opacity: 0.1;
    background: #FFF;
    margin: 0 14px;
}

.btc__block-item-big-actions button {
    display: flex;
    align-items: center;
    transition: all 0.2s ease;
}

.btc__block-item-big-actions button svg {
    margin-right: 6px;
}

.btc__block-item-big-words {
    color: #FFF;
    font-size: 12px;
    font-weight: 400;

    opacity: 0.3;
}

.btc__block-item-big.added {
    border-radius: 12px;
    background: #FF8114;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.10);
}

.btc__block-item-big.added .btc__block-item-big-paragraph {
    color: #331A04;
}

.btc__block-item-big.added .btc__block-item-big-words {
    opacity: 0.6;
    color: #331A04;
}

.btc__block-item-big.added .btc__block-item-big-actions {
    color: #331A04;
}
.btc__block-item-big.added .btc__block-item-big-title {
    color: #331A04;
}

.btc__block-item.btc__block-item-fix-delete {
    position: relative;
    border-radius: 24px;
    border: 1px solid #383838;
    background: #202020;
    display: flex;
    padding: 5px 6px 5px 10px;
}
.input-search-variant-two {
    position: relative;
}
.input-search-variant-two input {
    width: 100%;
    display: flex;
    height: 40px;
    padding: 8px 8px 8px 35px;
    align-items: center;
    margin-bottom: 16px;

    border-radius: 8px;
    border: 1px solid #333;
    background: #1B1B1B;

    color: #FFF;
    font-size: 14px;
    font-weight: 400;
    opacity: 0.3;
}

.input-search-variant-two svg {
    position: absolute;
    top: 10px;
    left: 10px;
    opacity: 0.3;
}

/* new generated content sidebar */

.gen-sidebar-wrapper {
    display: flex;
    height: 100vh;
}

.gen-sidebar-nav {
    display: flex;
    flex-direction: column;
    width: 72px;
    border-right: 1px solid #1F1F1F;
}
.gen-sidebar-nav-item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 72px;
    height: 72px;
    border-right: 1px solid transparent;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
}
.gen-sidebar-nav-item:last-child {
    margin-top: auto;
}

.gen-sidebar-nav-item.logo {
    height: 60px;
}
.gen-sidebar-nav-item.logo img {
    width: 30px;
    height: 20px;
}
.gen-sidebar-nav-item span {
    font-size: 10px;
    font-weight: 500;
    opacity: 0.4;
    margin-top: 8px;
    transition: all 0.3s ease;
}

.gen-sidebar-nav-item svg {
    opacity: 0.4;
    transition: all 0.3s ease;
}
.gen-sidebar-nav-item:not(.no-active-styles).active svg {
    opacity: 1;
}

.gen-sidebar-nav-item:not(.no-active-styles).active {
    position: relative;
    background: #151515;
}

.gen-sidebar-nav-item:not(.active):hover span {
    opacity: 0.6;
}

.gen-sidebar-nav-item:not(.active):hover svg {
    opacity: 0.6;
}

.gen-sidebar-nav-item:not(.no-active-styles).active svg path, .gen-sidebar-nav-item:not(.no-active-styles).active svg rect {
    stroke: #FF8114;
}
.gen-sidebar-nav-item:not(.no-active-styles).active span {
    opacity: 0.9;
}
.gen-sidebar-nav-item:not(.no-active-styles).active:before, .gen-sidebar-nav-item:not(.no-active-styles).active:after {
    content: '';
    position: absolute;
    background: #151515;
    width: 13px;
    height: 11px;
    right: -1px;
    z-index: 1;
}

.gen-sidebar-nav-item.active:before {
    top: -11px;
}
.gen-sidebar-nav-item.active:after {
    bottom: -11px;
}

.gen-sidebar-nav-item:not(.no-active-styles).active-top {
    border-radius: 0px 0px 12px 0px;
    border-right: 1px solid #1F1F1F;
    border-bottom: 1px solid #1F1F1F;
    z-index: 2;
    background: #111111;
}

.gen-sidebar-nav-item:not(.no-active-styles).active-bottom {
    z-index: 2;
    border-radius: 0px 12px 0px 0px;
    border-right: 1px solid #1F1F1F;
    border-top: 1px solid #1F1F1F;
    background: #111111;
}

.gen-sidebar-content {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    width: 0px;
    background: #151515;
    overflow: hidden;
    transition: all 0.3s ease;
}

.gen-sidebar-content.open {
    width: 350px;
    border-right: 1px solid #1F1F1F;
}

.gen-sidebar-header {
    display: flex;
    width: 350px;
    padding: 13px 16px;
    justify-content: space-between;
    align-items: center;
}

.gen-sidebar-header-title {
    font-size: 14px;
    font-weight: 500;
}
.gen-sidebar-header-subtitle {
    font-size: 12px;
    font-weight: 400;
    opacity: 0.4;
}

.gen-sidebar-body {
    width: 350px;
    padding: 0 16px;
    flex: 1;
    overflow: auto;
}

.gen-sidebar-body::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.gen-sidebar-body:has(.blog-toolkit-wrapper) {
    padding: 0;
}

.gen-sidebar-body-part {
    display: none;
}

.gen-sidebar-body-part.show {
    display: block;
}

.gen-sidebar-content .gen4-input-suggestion {
    width: auto;
}

.gen-sidebar-divider {
    height: 1px;
    background: #1F1F1F;
    width: 100%;
    margin: 20px 0;
}

.gen-sidebar-footer {
    height: 66px;
    padding: 13px 10px;
}

.gen-sidebar-btn {
    display: flex;
    width: 100%;
    height: 40px;
    padding: 20px;
    justify-content: center;
    align-items: center;

    color: #331A04;
    font-size: 14px;
    font-weight: 600;

    border-radius: 360px;
    border-bottom: 1px solid #FFF;
    background: radial-gradient(178.28% 111.8% at 0% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
}

.loader-spin-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
}

.loader-spinner {
    width: 70px;
    height: 70px;
    animation: spin 1.3s linear infinite;
}

@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }

  .loader-spin-title {
    opacity: 0.9;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
  }

  .loader-spin-subtitle {
    color: var(--secondary-lighten-60);
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;

    opacity: 0.5;
  }

.progress-gradient {
    background: radial-gradient(225.51% 141.42% at 100% 100%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
}

.modal-button {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 36px;
    border: 1px solid #333;
    border-radius: 360px;
    background: #1B1B1B;
    font-size: 14px;
    color: var(--secondary-lighten-60);
    font-weight: 500;
}

.modal-button:hover {
    border: 1px solid #fdfbfb;
}

.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
}

.pagination-prev-btn, .pagination-next-btn {
    display: flex;
    height: 36px;
    padding: 11px 14px;
    align-items: center;
    border-radius: 360px;
    background: radial-gradient(178.28% 111.8% at 0% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    color: #331A04;
    font-size: 14px;
    font-weight: 500;
}

.small .pagination-prev-btn,
.small .pagination-next-btn {
    padding: 11px 8px;
}

.pagination-prev-btn svg path,
.pagination-next-btn svg path {
    stroke: #331A04;
}

.pagination-prev-btn.disabled svg path, .pagination-next-btn.disabled svg path {
    stroke: var(--secondary-lighten-60);
}

.pagination-prev-btn.disabled, .pagination-next-btn.disabled {
    border: 1px solid #333;
    opacity: 0.3;
    background: #1B1B1B;

    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-weight: 400;
}


.pagination-prev-btn svg {
    margin-right: 8px;
}
.pagination-next-btn svg {
    margin-left: 8px;
}
.small .pagination-prev-btn svg {
    margin-right: 0;
}
.small .pagination-next-btn svg {
    margin-left: 0;
}
.pagination-page-wrap {
    display: flex;
    align-items: center;
    padding: 0 12px;
}



.pagination-page-btn {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 11px 14px;
    justify-content: center;
    align-items: center;

    border-radius: 360px;
    border: 1px solid #333;

    background: #1B1B1B;

    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-weight: 400;
}

.pagination-page-btn:not(:last-child) {
    margin-right: 8px;
}

.pagination-page-btn.active {
    color: #FF8114;
    border: 1px solid #FF8114;
}

.pagination-page-divider {
    display: flex;
    width: 36px;
    height: 36px;
    padding: 11px 14px;
    justify-content: center;
    align-items: center;
    margin-left: -8px;
}

.coming-soon-badge {
    position: absolute;
    bottom: 16px;
    right: -33px;
    background-color: #ff9900;
    color: #ffffff;
    padding: 5px 30px;
    font-size: 12px;
    border-radius: 5px;
    transform: rotate(318deg);
    transition: 0.3s all ease-in-out;
  }

  .documents-card:hover .coming-soon-badge {
    background-color: #1b1b1b;
  } 

.new-words-dropdown {
    margin-top: 8px;
    border: 1px solid rgb(51, 51, 51);
    border-radius: 8px;
    background: rgb(27, 27, 27);
    padding: 4px;
    width: 100%;
    max-height: 142px;
    overflow-y: auto;
}
.new-words-dropdown a {
    color: rgb(167, 167, 167);
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    padding: 5px 10px;
    display: block;
}
.new-words-dropdown a:hover {
    color: #FF8114;
}
.new-words-dropdown a.active {
    pointer-events: none;
    text-decoration: line-through;
    opacity: 0.7;
}
.kit-details-page-content {
    color: var(--secondary-lighten-60);
    font-size: 14px;
}
.kit-details-page-content p {
    margin-bottom: 10px;
}
.kit-details-page-content h1,
.kit-details-page-content h2,
.kit-details-page-content h3,
.kit-details-page-content h4 {
    font-weight: bold;
}
.kit-details-page-content h1 {
    font-size: 22px;
    margin-bottom: 15px;
}
.kit-details-page-content h2 {
    font-size: 20px;
    margin-bottom: 10px;
}
.kit-details-page-content h3 {
    font-size: 18px;
    margin-bottom: 5px;
}
.kit-details-page-content h4 {
    font-size: 16px;
    margin-bottom: 3px;
}
.want-to-create-wrpr {
    position: relative;
    top: 62px;
    left: 0;
    width: 100%;
    max-height: calc(100dvh - 62px);
    overflow-y: auto;
}

.tabs-chart-block {
    padding: 20px;
    width: 50%;
}

.tabs-chart-block {
    border-right: 1px solid #424242;
}

.table-block {
    padding: 20px 24px 15px;
    width: 50%;
}

.add-chart-table {
    max-height: 303px;
    overflow: auto;
}

.table-chart {
    width: 100%;
    border-collapse: separate;
}
.modal__dialog--1000 {
    max-width: 1000px;
}
.add-chart-table th, .add-chart-table tr td:first-child {
    background-color: #2c2c2c;
    color: #e8e8e8;
    font-size: 13px;
    font-weight: 400;
    text-align: center;
}

.add-chart-table td, .add-chart-table th {
    height: 28px;
}
.add-chart-table tr td:not(:first-child) {
    border: 1px solid #2c2c2c;
}
.add-chart-table td {
    border-radius: 4px;
    font-size: 12px;
    line-height: 9px;
    text-align: center;
}

.add-chart-table input {
    font-size: 14px;
    border-bottom: 0;
    padding: 0;
}

.add-chart-table::-webkit-scrollbar {
    display: block;
    height: 6px;
    width: 6px;
}

.add-chart-table::-webkit-scrollbar-thumb {
    background: #999;
    border-radius: 360px;
}

.add-chart-table::-webkit-scrollbar-track {
    background: #292929;
}

.gy-12 {
    --bs-gutter-y: 12px;
}
.gx-12 {
    --bs-gutter-x: 12px;
}

.input-color-picker {
    /* border: 2px solid #fff; */
    border-radius: 3px;
    /* box-shadow: 0 2px 4px 0 #85858529, 0 0 1px 0 #1a202452; */
    display: block;
    height: 18px;
    min-width: 18px;
    width: 18px;
}

.input-color-picker::-webkit-color-swatch-wrapper {
    padding: 0;
}

.input-color-picker::-webkit-color-swatch {
    border: none;
}

.ql-editor .chart-wrapper {
    margin-top: 25px;
}
.goal-generator-input.--type-textarea {
    height: auto;
}

@media (max-width: 768px) {
    .tabs-chart-block,
    .table-block {
        width: 100%;
        border: 0;
    }
    .modal-outside-close-btn {
        left: calc(100% - 16px);
    }
}


.all-tools .select-input {
    color: white;
    font-size: 14px;
}
.inherit-card-styles *{
    background-color: inherit!important;
    color: inherit!important;
    font-size: inherit!important;
    margin: 0!important;
    padding: 0!important;
    font-family: 'Inter', sans-serif!important;
    text-align: inherit!important;
    font-weight: inherit!important;
    line-height: 18px!important;
}
/* .all-tools .select-input::after {
    white-space: normal!important;
} */