.btn {
    border: 1px solid transparent;
    color: var(--defaultTextColor);
    font-size: var(--defaultFontSize);
    line-height: var(--defaultLineHeight);
    background-color: transparent;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all .3s ease;
    -o-transition: all .3s ease;
    transition: all .3s ease;
    text-align: center;
}
.btn:hover {
    opacity: .8;
}

.btn--primary {
    background-color: var(--primary); 
    border-color: var(--primary);
    color: var(--primary-darken-80);    
}
.btn--black-10 {
    background-color: var(--black-20); 
    border-color: var(--black-10);
    color: var(--white);    
}

.btn--primary-gradient {
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    
    color: var(--primary-darken-80);   
}


.btn.disabled {
    pointer-events: none;
    opacity: .65;
}




.btn--lg {
    padding: 10px 16px;
}

.btn--md {
    padding: 6px 10px;
}