
.skeleton::before{
    /* background: none; */
    content: none;
}
/* трансформация текста */
.skeleton h2,
.skeleton .dropdown:has(h3),
.skeleton p:not(.skeleton-ignore),
.skeleton span:not(.ico):not(.icon),
.skeleton .input-custom .input-custom__label,
.skeleton .toolbar-body-nav .tab {
    color: rgba(0, 0, 0, 0);
    height: 6px;
    border-radius: 360px;
    background: #1B1B1B;
    width: fit-content;
    display: inline-block;
    line-height: 6px;
    user-select: none;
    pointer-events: none;

}

/* перерисовка блока */
.skeleton .btn,
.skeleton .input-custom .input-custom__input,
.skeleton .input-custom.input-custom-wrapper .select-selection,
.skeleton .input-custom .input-custom__wrap-input,
.skeleton .input-custom .textarea,
.skeleton .keywords-container .keywords-select-item,
.skeleton .keywords-container .keywords-suggestion-item,
.skeleton .toolbar-body-suggestions .suggestion-options-item,
.skeleton .toolbar-body-suggestions .input-box-list-item,
.skeleton .toolbar-body-header,
.skeleton .toolbar-body-reload,
.skeleton .suggestion-list .suggestion-options-item.suggestion-list-item{
    color: rgba(0, 0, 0, 0);
    background: #1B1B1B;
    pointer-events: none;
    border:none !important;
}

/* скрытие внутренних частей блока */
.skeleton .btn svg,
.skeleton .input-custom .input-custom__input::placeholder,
.skeleton .input-custom .textarea::placeholder,
.skeleton .input-custom .input-custom__wrap-input div,
.skeleton .input-custom .input-custom__label svg,
.skeleton .input-custom .input-custom__wrap-input .input-icon,
.skeleton .input-custom .input-custom__wrap-input .textarea,
.skeleton .input-custom .input-custom__wrap-input svg,
.skeleton .keywords-container .keywords-select-item svg,
.skeleton .toolbar-body-suggestions .input-box-list-item svg, 
.skeleton .toolbar-body-header svg,
.skeleton .toolbar-body-suggestions .suggestion-options-item svg,
.skeleton .toolbar-body-reload svg,
.skeleton .toolbar-sidebar-header .back-arrow-link svg,
.skeleton .toolbar-body-nav+.tabs-underline,
.skeleton .suggestion-list .suggestion-options-item.suggestion-list-item svg,
.skeleton .input-custom.input-custom-wrapper .select-selection div,
.skeleton h3+svg,
.skeleton .dropdown:has(h3) h3
{
    color: rgba(0, 0, 0, 0);
    opacity: 0;
    background: transparent;
}
.skeleton .toolbar-sidebar-header .back-arrow-link{
    color: rgba(0, 0, 0, 0);
    background: #1B1B1B;
    pointer-events: none;
    border:none !important;
    border-radius: 50%;
}



.skeleton .sleketon-hide-element {
    opacity: 0;
}
.skeleton .toolbar-body-nav .tab{
    padding: 0;
    margin: 5px;
}
.skeleton .toolbar-body-nav{
    align-items: center;
    height: 53px;
}
.toolbar-toolkit{
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    padding: 14px 20px;
    border-bottom: 1px solid #262626;
    color: var(--secondary-lighten-60);
}
.toolbar-toolkit__selected{
    display: flex;
    width: 18px;
    height: 18px;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    border-radius: 50%;
    background: #202020;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px; 
}
.btc__block-head-title{
    color: var(--secondary-lighten-60);
    font-size: 12px;
    line-height: 14px; 
    text-transform: unset;
}
.btc__block-content.toolkit-block-wrapper{
    display: grid;
    width: 100%;
}
.btc__block-content.elements-2{
    grid-template-columns: repeat(2, calc( 50% ));
    /* margin: 0 -4px; */

}
.btc__block-content.elements-3{
    grid-template-columns: repeat(3, calc( 100%/3 ));
    /* margin: 0 -3px; */

}
.btc__block-content.toolkit-block-wrapper .btc__block-item{
    padding: 8px 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    height: unset;
    margin-bottom: 8px;
}
.btc__block-content.elements-2 .btc__block-item{
    border-radius: 24px;
    border: 1px solid #383838;
    background: #202020;
    position: relative;
    margin: 0 4px;
    margin-bottom: 8px;
    
}
.btc__block-content.elements-2 .btc__block-item span{
    max-width: 90%;
    display: block;
    text-align: center;
}
.btc__block-content.elements-3 .btc__block-item{
    background: #202020;
    text-align: center;
    margin-bottom: 0;
    margin: 0 3px;
    margin-bottom: 6px;

}
.btc__block-content.toolkit-block-wrapper .btc__block-item svg{
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
}
/*.blog-toolkit-wrapper{*/
/*    width: unset;*/
/*}*/
.border-primary {
    border: 1px solid #FF8114;
}
.border-grey {
    border: 1px solid #383838;
}
.keep-it-positive-badge-item {
    display: flex;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 14px;
    padding-right: 10px;
    border-radius: 24px;
    border: 1px solid #383838;
    background: #202020;
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
}
.keep-it-positive-badge-item.add-btn {
    background: #151515;
}
.keep-it-positive-badge-item.active,
.keep-it-positive-badge-item:not(.active):hover {
    border: 1px solid #FF8114;
    background: #151515;
    color: #FF8114;
}
.keep-it-positive-badge-item.active svg path,
.keep-it-positive-badge-item svg path {
    transition: 0.3s all ease-in-out;
}
.keep-it-positive-badge-item:not(.active):hover svg path,
.keep-it-positive-badge-item.active svg path {
    fill: #FF8114;
    fill-opacity: 1;
}
.keep-it-positive-badge-item .keep-it-positive-remove-btn {
    display: none;
    border-radius: 50%;
}
.keep-it-positive-badge-item .keep-it-positive-remove-btn svg path {
    fill: white;
    fill-opacity: 0.5;
}
.keep-it-positive-badge-item .keep-it-positive-remove-btn:hover {
    background: #202020;
}
.keep-it-positive-badge-item .keep-it-positive-remove-btn:hover svg path {
    fill: #FF8114;
    fill-opacity: 1;
}
.keep-it-positive-badge-item.active:hover .keep-it-positive-check-icon,
.keep-it-positive-badge-item:hover .keep-it-positive-plus-icon {
    display: none;
}
.keep-it-positive-badge-item:hover .keep-it-positive-remove-btn {
    display: flex;
}