:root {
    --defaultTextColor: #ffffff;
    --white: #fff;
    --darck-color: #111;
    --white-50: rgba(255, 255, 255, 0.50);
    --white-60: rgba(255, 255, 255, 0.60);
    --black-20: rgba(0, 0, 0, 0.20);
    --black-10: rgba(0, 0, 0, 0.10);
    --primary: #FF8114;
    --primary-10: #EA8207;
    --primary-darken-60: #1B1B1B;
    --primary-darken-70: #262626;
    --primary-darken-80: #331A04;
    --secondary-lighten-10: #393939;
    --secondary-lighten-30: #707070;
    --secondary-lighten-40: #9D9D9D;
    --secondary-lighten-50: #A3A2A1;
    /* --secondary-lighten-60: #A7A7A7; */
    --secondary-lighten-60: #e6e5e5;
    --warning: yellow;
    --danger: red;
    /* tipografy */
    --fotnFamily: 'Poppins', sans-serif;
    --defaultFontSize: 16px;
    --defaultLineHeight: 18px;
    --h1Size: 50px;
    --h2Size: 32px;
    --h3Size: 20px;
    --h4Size: 20px;
    --h5Size: 10px;
    --defaultRaduis: 5px;
    --header-fixed-padding: 67px;
}

body {
    font-size: 100%;
    font-weight: 300;
    font-family: var(--fotnFamily);
    font-weight: 400;
    font-size: var(--defaultFontSize);
    line-height: var(--defaultLineHeight);
    -ms-text-size-adjust: 100%;
    -moz-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    color: var(--defaultTextColor);
    background-color: var(--darck-color);
}

body.lock {
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-size: inherit;
    font-weight: bold;
    font-family: var(--fotnFamily);
    line-height: 120%;
}

h1 {
    font-size: var(--h1Size);
}

h2 {
    font-size: var(--h2Size);
}

h3 {
    font-size: var(--h3Size);
}

h4 {
    font-size: var(--h4Size);
}

h5 {
    font-size: var(--h5Size);
}

.input,
.textarea {
    padding: 10px 0;
    font-size:  var(--defaultFontSize);
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid #BABDDB;
}
input,
button,
textarea {
    font-family: var(--fotnFamily);
}

.radius-5 {
    border-radius: var(--defaultRaduis);
}
.wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    min-height: 100%;
    overflow: hidden;
}
.header-fixed {
    padding-top: var(--header-fixed-padding);
}
.content {
    flex: auto;
}
.container {
    max-width: none;
    margin: 0 auto;
    width: 100%;
    padding: 0 15px;
}

/* colors */

.color-primary {
    color: var(--primary) !important;
}
.color-danger {
    color: var(--danger);
}
.color-warning {
    color: var(--warning);
}
.color-secondary {
    color: var(--secondary);
}

/* bg-colors */

.bg-primary {
    background-color: var(--primary);
}
.bg-danger {
    background-color: var(--danger);
}
.bg-warning {
    background-color: var(--warning);
}
.bg-secondary {
    background-color: var(--secondary);
}