.table-wrapper {
  width: 100%;
  border: 1px solid #262626;
  border-radius: 8px;
}
.table-default {
  width: 100%;
  padding: 12px 16px;
  color: #fff;
  white-space: nowrap;
  border-collapse: collapse;
}

.table-default tr th {
  text-align: left;
  padding: 20px 30px;
  font-weight: 500;
  background-color: #1b1b1b;
  border-bottom: 1px solid #262626;
}
.table-default tr td {
  padding: 10px 30px;
  color: var(--white-60);
  background-color: #1b1b1b;
}
.image-sm {
  width: 41px;
  height: 41px;
  border-radius: 41px;
}
.image-wrapper {
  overflow: hidden;
}
.image-wrapper img {
  width: 100%;
  height: 100%;
}

.card {
  display: flex;
  flex-direction: column;
}
.card.card-border {
  border: 1px solid #262626;
  border-radius: 8px;
  padding: 30px;
}

.hr-default {
  background-color: #707070;
  height: 1px;
  width: 100%;
}

.btns-group {
  display: flex;
  align-items: center;
}

.modal-default .modal__content {
  background-color: var(--secondary-lighten-10);
  color: var(--white);
  border-radius: 12px;
}
.modal-default .modal__header {
  padding: 24px 35px;
}
.modal-default .modal__footer {
}
.modal-default .btn-close {
  position: static;
  transform: none;
}
.modal__dialog--660 {
  max-width: 660px;
}

.upload-image {
  display: flex;
  align-items: center;
}
.upload-image__preview {
  width: 70px;
  height: 70px;
  min-height: 70px;
  border-radius: 70px;
  margin-right: 10px;
}
.upload-image__text {
  font-size: 13px;
  font-weight: 400;
  line-height: normal;
  color: var(--white-60);
}
