.input-custom__label{
    color: var(--secondary-lighten-60);
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.input-custom__wrap-input{
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.input-custom__input{
    /* border: 1px solid #262626;
    background-color: #1B1B1B; */
    border: 1px solid #565656;
    background-color: #1f1f1f;
    border-radius: 8px;
    padding: 12px 16px;
    color: #fff;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
    width: 100%;
    height: 48px;
}

.textarea{
    border: 1px solid #262626;
    background-color: #1B1B1B;
    border-radius: 8px;
    padding: 12px 16px;
    color: #fff;
    line-height: normal;
    font-size: 14px;
    font-weight: 300;
    width: 100%;
    resize: none;
}

.product-description__item.active .product-description__item-number,
.product-description__item.active .product-description__item-text{
    color: #fff;
}

.product-description__item{
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid transparent;
}

.product-description__list li:not(:last-child){
    margin-bottom: 10px;
}

.product-description__item.active{
    border-radius: 8px;
    border: 1px solid #262626;
    background-color: #1B1B1B;
}

.product-description__item-number{
    font-size: 16px;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.25);
}

.product-description__body{
    border-radius: 8px;
    border: 1px solid #262626;
    background: #1B1B1B;
    flex: auto;
}

.product-description__textarea{
    padding: 16px 20px;
    border: none;
    background-color: transparent;
}


.product-description__textarea-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #262626;
    padding: 8px 20px;
}

.product-description__item-text{
    display: block;
    max-width: 250px;
    font-size: 14px;
    font-weight: 300;
    text-overflow: ellipsis;
    overflow: hidden;
    color: rgba(255, 255, 255, 0.25);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.product-description__group{
    display: flex;
    align-items: flex-start;
}

.checkbox-circle .custom-checkbox-fake{
    border-radius: 50%;
}

.checkbox-circle input:checked + .custom-checkbox-fake{
    background-size: 14px;
}

.circle-20x20{
    width: 20px;
    height: 20px;
    min-width: 20px;
    border-radius: 360px;
    border: 1.5px solid #656565;
}

.height-44 .input-custom__input{
    height: 44px;
}

.icon-left .input-custom__input{
    padding-left: 56px;
}

.icon-left .icon-input{
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.icon-left.height-44 .icon-input{
    left: 10px;
}


.checkbox-circle-2 .custom-checkbox-fake{
    border-radius: 50%;
}

.custom-checkbox.checkbox-circle-2 input:checked + .custom-checkbox-fake{
    background-image: none;
    position: relative;
}

.custom-checkbox.checkbox-circle-2 input:checked + .custom-checkbox-fake::after{
    /*
    content: ' ';
    position: absolute;
    border-radius: 50%;
    width: 12px;
    height: 12px;
    min-width: 12px;
    background-color: #EA8207;
    background-color: #EA8207;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);*/

    content: " ";
    position: relative;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background-color: #EA8207;
    /* top: 50%; */
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
    display: flex;
    justify-content: center;
    border: 3px solid #161616;
}

/* =====select-default===== */

.choices__inner{
    background-color: #1B1B1B;
    border: 1px solid #262626;
    border-radius: 8px;
    height: 48px;

    display: flex;
    align-items: center;
}
.choices__list[aria-expanded] .choices__item::after,
.choices__list[aria-expanded] .choices__item::before{
    display: none !important;
}
.choices[data-type*=select-one]::after{
    content: url('../../img/icons/arrow_drop_down.svg');
    border: none;
    width: 32px;
    height: 32px;
    right: /*16px*/ 0;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0 !important;
}

.choices[data-type*=select-one].is-open::after{
    margin-top: 0;
    transform: translateY(-50%) rotate(180deg);
}

.is-open .choices__inner{
    border-radius: 8px 8px 0 0;
}

.is-focused .choices__inner, .is-open .choices__inner{
    border-color: #262626;
}

.choices__list--dropdown .choices__item--selectable, 
.choices__list[aria-expanded] .choices__item--selectable{
    padding-right: 10px;
}

.choices__list--dropdown .choices__item, 
.choices__list[aria-expanded] .choices__item{
    color: #1B1B1B;
    background-color: #fff;
}

.link-list .btn{
    width: 100%;
    justify-content: space-between;
    white-space: nowrap;
    font-size: 12px;
    text-transform: uppercase;
    border-radius: 8px;
}

.link-list li {
    margin-bottom: 10px;
}

.icon{display: flex;}

.icon-16x16{
    width: 16px;
    height: 16px;
}

.links-tutorial{
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.link-tutorial{
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    padding: 20px;
    width: 233px;
    height: 200px;
    border-radius: 16px;
    background-color: rgba(33, 33, 33, 0.70);
}

.link-tutorial + .link-tutorial{
    margin-left: 20px;
}

.textarea-with-suggestion{
    height: auto;
}

.textarea-with-suggestion textarea{
    color: #fff;
}

.common-pages{
    padding: 8px 25px 8px 16px;
    border-radius: 8px;
    border: 1px solid #262626;
    background: #1B1B1B;
    position: relative;
}

.common-pages-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -8px;
}

.common-pages-item{
    border-radius: 20px;
    background: #EA8207;
    display: flex;
    align-items: center;
    padding: 5px 15px;
    max-width: max-content;
    margin-right: 8px;
    margin-bottom: 8px;
}

.common-pages-item-text{
    color: #fff;
    font-size: 14px;
    font-weight: 400;
}

.common-pages__arrow{
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.form-search__input{
    padding: 18px 5px 18px 16px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.50);
    height: 100%;
    background-color: transparent;
    flex: auto;
}

.form-search{
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #262626;
    background: #1B1B1B;
    overflow: hidden;
    position: relative;
}

.delete-value{
    width: 30px;
    height: 30px;
    min-width: 30px;
    border-radius: 29px;
    background-color: #232323;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 71px;
    top: 50%;
    cursor: pointer;
    transform: translateY(-50%);
}

.form-search__btn{
    width: 42px;
    height: 42px;
    min-width: 42px;
    border-radius: 29px;
    background-color: #232323;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

/* ==========/////======================== */

.arrows-group-right{
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
    height: 25px;
    justify-content: space-between;
}

.arrow-up{
    transform: rotate(180deg);
}

.arrow-up,
.arrow-down{
    cursor: pointer;
    background-color: transparent;
    display: flex;
}

.number-input .input-custom__input::-webkit-outer-spin-button,
.number-input .input-custom__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.keywords{
    padding: 8px 10px;
    border-radius: 8px;
    border: 1px solid #262626;
    background: #1B1B1B;
}

.keywords-item{
    display: flex;
    align-items: center;
    padding: 5px 11px;
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #232323;
    width: max-content;
}

.keywords-item-text{
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.50);
    padding: 0 6px;
}

.keywords-item{
    margin-right: 8px;
    margin-bottom: 8px;
}

.keywords-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 8px;
}
.keywords-input{
    color: rgba(255, 255, 255, 0.25);
    background-color: transparent;
    font-size: 14px;
    width: 100%;
    font-weight: 300;
}

/* .data-input .icon{
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
} */

.data-input .input-custom__input::-webkit-calendar-picker-indicator {
    color: #EA8207;
    opacity: 1;
    display: block;
    background: url('../../img/icons/icon-data.svg') no-repeat;
    width: 20px;
    height: 20px;
    border-width: thin;
    filter: invert(0);
}

.form-search__input::-ms-clear {
    display: none;
    width : 0;
    height: 0;
}

.data-input-white .input-custom__input{
    padding-left: 46px;
}

.data-input-white .input-custom__input::-webkit-calendar-picker-indicator{
    color: #fff;
    opacity: 1;
    display: block;
    background: url('../../img/icons/icon-calendar.svg') no-repeat;
    width: 15px;
    height: 15px;
    border-width: thin;
    filter: invert(0);
    position: absolute;
    left: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.select-group{
    display: flex;
}

.select-group-arrows{
    display: flex;
    flex-direction: column;
    margin-left: 14px;
    margin-right: 14px;
    margin-top: 6px;
}

.select-arrow-right,
.select-arrow-left{
    background-color: transparent;
}

.select-group .textarea{
    border-radius: 20px;
    background-color: rgba(217, 217, 217, 0.30);
    font-size: 12px;
}

.select-group .textarea::placeholder{
    color: rgba(255, 255, 255, 0.30);
}

.select-group .choices__inner{
    color: rgba(255, 255, 255, 0.50);
    border-radius: 20px;
    border: 1px solid #000;
    background-color: #232323;
    height: 41px;
}

.select-group .choices[data-type*=select-one]::after{
    content: url('../../img/icons/arrow-select-2.svg');
    border: none;
    width: 16px;
    height: 16px;
    right: 19px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: 0px;

}

.select-group .choices[data-type*=select-one].is-open::after{
    transform: translateY(-50%) rotate(180deg);
}

/* =============/////====================== */


/* =============pricing-card============== */
.popular-text{
    font-weight: 500;
    font-size: 14px;
    color: #331A04;
    border-radius: 32px;
    background-color: #FF8114;
    padding: 6px 32px;
    position: absolute;
    top: -15px;
    left: 50%;
    transform: translateX(-50%);
}

.pricing__card.popular{
    border: 2px solid #FF8114;
    position: relative;
}

.popular .btn--primary-outline{
    border: none;
    color: #331A04;
    background-color: #FF8114;
    box-shadow: 4px 4px 16px 0px rgba(255, 129, 20, 0.25);
}
.pricing__card{
    padding: 48px 40px;
    background-color: #2E2E2E;
    border: 1px solid #393939;
    border-radius: 16px;
}

.pricing__title{
    font-size: 32px;
    font-weight: 700;
    color: #fff;
}

.pricing__subtitle{
    font-size: 16px;
    font-weight: 500;
    color: var(--secondary-lighten-60);
    margin-bottom: 40px;
}

.pricing__price-group{
    display: flex;
    align-items: baseline;
    margin-bottom: 8px;
}

.pricing__currency{
    font-size: 14px;
    color: #fff;
    font-weight: 500;
    line-height: 30px;
    margin-right: 8px;
}

.pricing__price{
    font-size: 35px;
    font-weight: 600;
    line-height: 30px;
    color: #fff;
}

.pricing__item{
    display: flex;
    align-items: center;
}

.pricing__item:not(:last-child){
    margin-bottom: 20px;
}

.pricing__header-group{
    text-align: center;
    margin-top: 40px;
}
/* =============/pricing-card============== */

/* =============Text Generator============== */

.text-generator-item{
    padding: 10px 20px;
    color: #fff;
    border-radius: 8px;
    background-color: #232323;
    font-size: 14px;
    width: 214px;
}

.text-generator-list{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-right: -5px;
}

.text-generator-list li{
    padding-left: 5px;
    padding-right: 5px;
    margin-bottom: 10px;
}
/* =============/Text Generator============== */


.color-secondary-lighten-40{color: var(--secondary-lighten-40);}
.color-secondary-lighten-60{color: var(--secondary-lighten-60);}
.color-primary-10{color: var(--primary-10)}

.btn-outline-secondary{
    border: 1px solid rgba(0, 0, 0, 0.20);
    background-color: rgba(0, 0, 0, 0.10);
    color: rgba(255, 255, 255, 0.50);
}

.btn-secondary-lighten-30{
    border: 1px solid rgba(38, 38, 38, 0.25);
    background-color: #707070;
    color: #fff;
}

.box-circle-30x30{
    width: 30px;
    height: 30px;
    min-width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 17px;
    background-color: #fff;
}

.input-btn-right .btn{
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}

.btn-outline-secondary-2{
    border: 1px solid rgba(0, 0, 0, 0.20);
    background-color: rgba(0, 0, 0, 0.10);
    color: #393939;
}

.btn-outline-secondary-2.active-btn{
    border: 1px solid rgba(0, 0, 0, 0.20);
    background-color: #393939;
    color: var(--secondary-lighten-60);
}

.btn-outline-secondary.view-on{
    border: 1px solid var(--primary-darken-80, #331A04);
    background: var(--primary, #FF8114);
    box-shadow: 4px 4px 16px 0px rgba(255, 129, 20, 0.25);
    color: #331A04;
}

.btn-outline-black{
    border: 1px solid #262626;
    background-color: #1B1B1B;
    color: rgba(255, 255, 255, 0.50);
}

.btn-outline-primary-10{
    border: 1px solid #EA8207;
    background-color: rgba(0, 0, 0, 0.20);
}

.btn-primary-10{
    background-color: #EA8207;
    color: #fff;
}

.btn-outline-white-50{
    border: 1px solid rgba(255, 255, 255, 0.50);
    color: #fff;
}

.btn--primary-outline{
    color: #FF8114;
    border: 1px solid #393939;
    background-color: #2E2E2E;
}

.color-secondary-lighten-60{
    color: var(--secondary-lighten-60);
}

.border{border: 1px solid #262626;}

.gx-30{--bs-gutter-x: 30px}

.gy-20{--bs-gutter-y: 20px}

.btn-md{padding: 5px 11px;}

.btn-md-2{padding: 9px;}

.btn-md-3{padding: 7px;}

.btn-md-4{padding: 7px 11px;}

.btn-lg-2{padding: 11px 15px;}

.btn-lg-3{padding: 9px 14px;}

.btn-lg-4{padding: 14px;}

.btn-lg-5{padding: 11px 22px;}

.btn-xl{padding: 16px 24px;}

.btn-xl-2{padding: 6px 19px;}

.input-custom__wrap-input.number-input .input-custom__input{
    padding-right: 45px;
}