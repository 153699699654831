.header {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 50;
    background-color: var(--darck-color);
}
.header.container {    
    padding: 0 10px;
}
/* .header:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
    z-index: 3;
} */
.header__menu {
    margin-left: auto;
}
.header__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 15px 0;
}

.header__burger {
    display: none;
    overflow: hidden;
}

.header__menu.active {
    top: 0;
}

.header__list {
    display: flex;
    align-items: center;
    position: relative;
}

.header__list li {
    display: flex;
    margin: 0 0 0 10px;
}

.header__link {
    padding:6px 10px;
    display: flex;
    align-items: center;
    color: var(--secondary-lighten-60, #A7A7A7);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
.btn.sidebar-btn {
    border: 0;
    color: var(--secondary-lighten-60, #A7A7A7);  
}
.header__link:hover,
.header__link.active,
.sidebar-btn:hover {
    color: var(--primary);
}
.header__link-icon {
    display: flex;
    align-items: center;
}
.header__link-icon:first-child{
    margin-right: 15px;
}
.header__link-icon:last-child {
    margin-left: 15px;
}
@media (max-width: 767px) {
    .header__burger {
        display: block;
        position: relative;
        width: 30px;
        height: 20px;
        z-index: 5;
    }

    .header__burger:before,
    .header__burger:after {
        content: '';
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
    }

    .header__burger:before {
        top: 0;
    }

    .header__burger:after {
        bottom: 0;
    }

    .header__burger.active:before {
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 9px;
    }

    .header__burger.active:after {
        -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
        transform: rotate(-45deg);
        bottom: 9px;
    }

    .header__burger.active span {
        -webkit-transform: translateX(-35px);
        -ms-transform: translateX(-35px);
        transform: translateX(-35px);
    }

    .header__burger span {
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        top: 9px;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
    }

    .header__menu {
        position: fixed;
        top: -200%;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: blueviolet;
        padding: 100px 0 0 0;
        -webkit-transition: all .5s ease;
        -o-transition: all .5s ease;
        transition: all .5s ease;
        overflow: auto;
    }
}