.p-20 {
    padding: 20px;
}
.pb-0 {
    padding-bottom: 0;
}
.pt-64 {
    padding-top: 64px;
}
.text-center {
    text-align: center;
}
.d-flex {
    display: flex;
}
.flex-col {
    flex-direction: column;
}
.items-center {
    align-items: center;
}
.mb-40 {
    margin-bottom: 40px;
}
.mb-48 {
    margin-bottom: 48px;
}
.radius-16 {
    border-radius: 16px;
}
.w-100 {
    width: 100%;
}
/* .header {
    padding: 6px 20px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #111111;
    z-index: 10;
}
.header nav {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 64px;
}
.header nav ul {
    display: flex;
    align-items: center;
}
.header nav ul li+li {
    margin-left: 30px;
}
.header nav ul li a {
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: var(--secondary-lighten-60);
}
.header nav ul li a:hover {
    color: #FF8114;
}
.header nav ul li a .icon-wrpr {
    display: flex;
    align-items: center;
    justify-content: center;
}
.header nav ul li a .icon-wrpr.icon-pos-left {
    margin-right: 15px;
}
.header nav ul li a .icon-wrpr.icon-pos-right {
    margin-left: 15px;
} */
.bg-gradient-section-box {
    border-radius: 16px;
    background: linear-gradient(180deg, #1B1B1B 0%, rgba(27, 27, 27, 0.00) 100%);
}
.main-title {
    color: #FFF;
    font-size: 32px;
    font-weight: 600;
    line-height: normal;
}
.product-desctription-box {
    padding: 48px 20px 0;
}
.search-input-form {
    position: relative;
    max-width: 600px;
    width: 100%;
    z-index: 2;
}
.search-input-form input {
    border-radius: 360px;
    background: rgba(255, 255, 255, 0.06);
    border: 1px solid #262626;
    padding: 11px 48px;
    display: block;
    width: 100%;
    color: #FFF;
    font-size: 14px;
    font-weight: 300;
}
.search-input-form input::placeholder {
    color: rgba(255, 255, 255, 0.25);
}
.search-input-form input:hover {
    border-color: #fff;
}
.search-input-form input:focus {
    border-color: #FF8114;
}
.search-input-form button {
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    position: absolute;
    left: 14px;
    top: 50%;
    transform: translateY(-50%);
}
.search-input-form.autofill-dropdown-open input {
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid #1B1B1B;
    border-color: #262626;
    box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20);
}
.categories-items {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    max-width: 790px;
}
.categories-items li .category-item {
    display: inline-flex;
    align-items: center;
    border-radius: 360px;
    border: 1px solid rgba(255, 255, 255, 0.12);
    background: rgba(0, 0, 0, 0.10);
    padding: 5px 8px;
    padding-left: 14px;
    margin: 4px;
    color: rgba(255, 255, 255, 0.6);
    font-size: 14px;
    font-weight: 400;
}
.categories-items li .category-item span {
    border-radius: 360px;
    background: #A2A2A2;
    color: #171717;
    text-align: center;
    font-size: 11px;
    font-weight: 600;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 16px;
    padding: 0px 4px;
    margin-left: 10px;
    transition: 0.15s all ease-in-out;
}
.categories-items li .category-item:hover {
    border: 1px solid #FF8114;
}
.categories-items li .category-item.active {
    background: #FF8114;
    color: #1B1B1B;
}
.categories-items li .category-item:hover span,
.categories-items li .category-item.active span {
    background: #171717;
    color: #FF8114;
}

.infinite-scroll-component__outerdiv {
    width: 100%;
}
.documents-cards-wrpr {
    display: flex;
    /* max-width: 1335px; */
    width: 100%;
    padding: 0 20px 20px;
    justify-content: center;
    gap: 20px;
    flex-wrap: wrap;
    margin: 0 auto;
}
.documents-card {
    border-radius: 16px;
    background: #1B1B1B;
    /* box-shadow: 8px 8px 16px 0px rgba(0, 0, 0, 0.20); */
    padding: 16px;
    width: calc(20% - 20px);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    color: #A2A2A2;
    transition: 0.3s all ease-in-out;
}
.documents-card:active {
    box-shadow: 8px 8px 26px 0 rgba(255, 130, 20, 0.7);
    color: #fff;
}
.documents-card .icon-wrpr {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 36px;
}
.documents-card h3 {
    margin-bottom: 18px;
    color: #FFF;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 12px;
    line-height: normal;
}
.documents-card p {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.12px;
}
.documents-card:hover {
    /* background: #FF8114;
    color: #331A04;
    transform: translateY(-5px);
    box-shadow: 8px 8px 16px 0 rgba(255, 130, 20, 0.3); */
    background: radial-gradient(178.28% 111.80% at 0.00% 0%, #F8B405 0%, rgba(248, 180, 5, 0.00) 100%), #FF8114;
    color: #1B1B1B;
}
.documents-card:hover h3 {
    color: #1B1B1B;
}
.lds-ring {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}
.lds-ring div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    margin: 8px;
    border: 4px solid #FF8114;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #FF8114 transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
    animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
    animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
    animation-delay: -0.15s;
}
@keyframes lds-ring {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.mob_nav_btn {
    display: none;
}
.header .sidebar-btn {
    /*display: none;*/
}
.header nav {
    margin-left: auto;
}
.sidebar-layout {
    display: flex;
}
.sidebar-layout aside.sidebar-nav {
    position: fixed;
    left: 0;
    top: 0;
    width: 280px;
}
.wrapper.sidebar-fixed .header {
    width: calc(100% - 280px);
    right: 0;
    left: auto;
    transition: .2s;
}
.wrapper.sidebar-fixed .header .sidebar-btn {
    display: inline-flex;
}
.wrapper.sidebar-fixed {
    height: 100dvh;

    /*overflow: auto;*/
}
.container-820{
    width: 100%;
    max-width: 820px;
}
.wrapper.sidebar-fixed .content {
    /* padding-top: 63px; */
    /* padding-left: 280px; */
    display: flex;
    align-items: flex-start;
    /* height: calc(100dvh - 63px); */
    height: 100dvh;
    transition: .2s;
    width: 100vw;
}
.sidebar-layout.sidebar-close aside.sidebar-nav {
    left: -280px;
}
.sidebar-layout.sidebar-close .wrapper.sidebar-fixed .content {
    padding-left: 0;
}
.sidebar-layout.sidebar-close .wrapper.sidebar-fixed .header {
    width: 100%;
}
.toolbar-sidebar {
    /* min-width: 400px; */
    /* width: 400px; */
    z-index: 2;
    width: 27.7vw;
}
.toolbar-sidebar-body {
    height: calc(100dvh - 140px);
    padding: 20px;
    border-radius: 16px 16px 0px 0px;
    background: #161616;
    overflow-y: auto;
}
.toolbar-sidebar:has(.toolbar-sidebar-footer) .toolbar-sidebar-body {
    height: calc(100dvh - 179px);
}
.toolbar-sidebar-footer {
    border-top: 1px solid #262626;
    background: #161616;
}
.toolbar-sidebar-footer .btn {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    padding: 18px 20px;
    min-height: 56px;
}
.toolbar-sidebar-header {
    background: var(--secondary-lighten-60);
    color: #393939;
    padding: 10px 20px 26px;
    margin-bottom: -16px;
    min-height: 97px;
}
.toolbar-sidebar-header p {
    font-size: 14px;
    font-weight: 400;
}
.toolbar-sidebar-header h3 {
    font-size: 24px;
    font-weight: 800;
}
.select-selection {
    border-radius: 8px !important;
    border: 1px solid #262626 !important;
    background: #1B1B1B !important;
    min-height: 48px !important;
    cursor: pointer !important;
    box-shadow: none !important;
}
.select-selection .aaa {
    color: #FFF !important;
    font-size: 14px !important;
    font-weight: 300 !important;
}
.select-selection .bbb {
    padding-left: 14px !important;
}
.select-menu-portal div {
    background: #1B1B1B;
}
.select-menu-portal .select2-results__option {
    background: #1B1B1B;
    color: var(--secondary-lighten-60);
    font-size: 13px;
    font-weight: 500;
    padding: 12px 16px;
    cursor: pointer;
}
.select-menu-portal .select2-results__option.select2-results__option--selected {
    background: rgba(255, 255, 255, 0.10);
    color: #fff;
}
.multi-select .select-selection .bbb {
    /* flex-direction: column; */
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 8px 0;
}
.multi-select .select-selection .bbb>.select-multi-value {
    border-radius: 4px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #232323;
}
.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value {
    color: rgba(255, 255, 255, 0.50);
    display: inline-flex;
    align-items: center;
    padding: 5px 3px;
    padding-left: 12px;
    line-height: 21px;
    font-size: 12px;
}
.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value:hover {
    background-color: transparent;
    color: #fff;
}
.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value+.select-multi-value {
    padding-left: 6px;
}
.multi-select .select-selection .bbb>.select-multi-value>.select-multi-value+.select-multi-value svg {
    margin: 0;
    width: 16px;
    height: 16px;
}
.multi-select .select-selection .bbb>.select-multi-value .select-multi-value svg {
    margin-right: 6px;
}
.multi-select .indicators-container {
    display: none;
}
.multi-select .select-input {
    width: 100%;
    display: block;
    padding: 0;
    margin: 0;
    padding-bottom: 3px;
    height: 32px;
    flex: initial;
    line-height: 34px;
    padding-right: 16px;
}
.multi-select .select-input input {
    display: block !important;
    width: 100% !important;
    background: transparent !important;
    font-size: 14px !important;
    font-weight: 300 !important;
    color: #fff !important;
}
.multi-select .select-input input::placeholder {
    color: rgba(255, 255, 255, 0.25);
}
.multi-select .select-placeholder {
    display: none;
}
.badges-group-wrpr {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
}
.badge-plus-item {
    padding: 5px 12px;
    border-radius: 24px;
    border: 1px solid rgba(255, 255, 255, 0.10);
    background: #161616;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.10);
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-weight: 400;
    display: inline-flex;
    align-items: center;
    margin: 3px;
}
.badge-plus-item.active {
    background: rgba(255, 255, 255, 0.05);
}
.badge-plus-item .badge-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 6px;
}
.badge-plus-item:hover {
    background: #FF8114;
    color: #fff;
}
.textarea-with-suggestion textarea {
    resize: none;
    width: 100%;
    display: block;
    padding-top: 4px;
    padding-left: 34px;
    background: transparent;
    border: 0;
    min-height: 142px;
    padding-bottom: 16px;
    
}
.textarea-with-suggestion {
    height: initial;
}
input::placeholder,
textarea::placeholder {
    color: rgba(255, 255, 255, 0.25);
}
.textarea-with-suggestion .input-icon {
    position: absolute;
    top: 16px;
    left: 16px;
}
.textarea-suggestion-block .badge-plus-item {
    background: #4B4B4B;
    font-size: 12px;
}
.textarea-suggestion-block p {
    color: rgba(255, 255, 255, 0.6);
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 10px;
}
.input-custom__input {
    padding-left: 14px;
    padding-right: 12px;
}
.input-custom__label {
    font-size: 14px;
}
.toolbar-sidebar-body::-webkit-scrollbar {
    width: 0;
    height: 0;
}
.sidebars-wrpr {
    display: flex;
    align-items: flex-start;
    min-height: 100dvh;
    position: relative;
    transition: 0.3s all ease-in-out;
    width: 50vw;
}
.content-box-wrpr {
    width: 50vw;
    transition: 0.3s all ease-in-out;
}
.sidebars-wrpr.toolkit-sidebar-close {
    width: 27.7vw;
}
.sidebars-wrpr.toolkit-sidebar-close+.content-box-wrpr {
    width: 72.3vw;
}
.sidebars-wrpr .blog-toolkit-wrapper {
    z-index: 1;
    transition: 0.3s all ease-in-out;
    opacity: 1;
    visibility: visible;
    position: absolute;
    right: 0;
    top: 0;
    width: 42.875%;
}
.sidebars-wrpr.toolkit-sidebar-close .blog-toolkit-wrapper {
    opacity: 0;
    visibility: hidden;
    transform: translateX(-100%);
}
.select-menu-portal {
    z-index: 99 !important;
}
.default-select.searchable .select-input {
    display: flex !important;
    flex: 1;
}
.default-select.searchable .select-input input {
    color: #fff !important;
    font-size: 14px;
    font-weight: 300;
}
.default-select.searchable .select-selection.has-value:not(.select-selection-focused) .select-input,
.default-select.searchable .select-selection:not(.select-selection-focused) .aaa.has-value+.select-input {
    opacity: 0;
}
.default-select.searchable .select-selection .bbb {
    display: flex !important;
}
.default-select-options a:hover,
.default-select-options a.active {
    background: rgba(255, 255, 255, 0.10);
}
.select-input textarea {
    width: 100%;
    display: block;
    resize: none;
    border: 0;
    background: transparent;
    line-height: normal;
    font-size: 14px;
    font-weight: 300;
}
.select-input.with-textarea {
    height: initial;
    margin-top: 16px;
}

@keyframes skeleton-animation {
    0% {
        right: 100%;
    }
    50% {
        right: -128px;
    }
    100% {
        right: 100%;
    }
}

.skeleton {
    overflow: hidden;
    position: relative;
}
.skeleton:before {
    display: block;
    position: absolute;
    width: 128px;
    height: 100%;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), #FFF, rgba(255, 255, 255, 0));
    opacity: 0.7;
    content: "";
    animation: skeleton-animation 1.25s infinite;
    z-index: 2;
}
.default-select.multi-select.one-line .select-input {
    width: initial;
    padding-left: 16px;
    flex: 1;
    min-width: 150px;
    margin: 0;
    padding-top: 7px;
}
.default-select.multi-select.one-line .select-input textarea {
    height: 26px;
}
.default-select.only-icon .select-selection .bbb {
    position: absolute;
    opacity: 0;
    top: 50%;
    transform: translateY(-50%);
}
.product-description-toolbar .select-selection {
    min-height: 36px !important;
    flex-wrap: initial !important;
}
.product-description-toolbar .select-selection-arrow {
    padding: 0 8px !important;
}
.product-description-toolbar .select-selection-arrow svg {
    width: 20px;
}
.product-description-toolbar .select-selection-arrow svg path {
    fill: var(--secondary-lighten-60);
    fill-opacity: 1;
}
.content-box-header h2 {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
}
.title-edit-input.input-custom__input {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    padding: 0 5px;
    height: inherit;
}

.dbl-btns{
    display: flex;
    flex-wrap: wrap;
}

.dbl-btns--20 > .btn:not(:last-child){
    margin-right: 20px;
}
.dbl-btns--10 > .btn:not(:last-child){
    margin-right: 10px;
}
.dbl-btns--5 > .btn:not(:last-child){
    margin-right: 5px;
}
.ico{
    display: flex;
    align-items: center;
}
.ico-36{
    min-width: 36px;
    max-width: 36px;
    height: 36px;
}
.ico-32{
    min-width: 32px;
    max-width: 32px;
    height: 32px;
}
.ico-30{
    min-width: 30px;
    max-width: 30px;
    height: 30px;
}
.ico-25{
    min-width: 25px;
    max-width: 25px;
    height: 25px;
}
.ico-13{
    min-width: 13px;
    max-width: 13px;
    height: 13px;
}
.title-24{
    font-size: 24px;
    line-height: normal;
}


.tabs-container{
    display: flex;
    flex-wrap: nowrap;
    margin-left: -10px;
    margin-right: -10px;
}
.tabs-container > div{
    padding-left: 10px;
    padding-right: 10px;
}
.tabs-container .tabs-sections{
    flex: 0 0 calc(440px + 20px);
    max-width: calc(440px + 20px);
}
.tabs-sections, .tabs-list{
    display: flex;
    flex-direction: column;
}
.tabs-list{
    overflow: hidden;
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.05); 
    border: 1px solid var(--secondary-lighten-10);
}
.btn-link{
    color: var(--secondary-lighten-60);
}
.active-tab .btn-link{
    color: var(--primary);
}
.tabs-list .tabs-list--item:not(:last-child){
    border-bottom: 1px solid var(--secondary-lighten-10);
}
.text-1{
    font-size: 12px !important;
    line-height: normal !important;
}
.text-2{
    font-size: 14px !important;
    line-height: normal !important;
}
.color-primary-10 {
    color: var(--primary-10) !important;
}
.text-white-05{color:var(--white-50) !important;}
.btn-primary-700{
    background-color: #F60;
    color: var(--white);
}
.row-heading{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.heading-item{
    display: flex;
    flex-direction: column;
}
.row-heading > .heading-item:not(:last-child){
    margin-right: 10px;
}
.bg---secondary-lighten-60{
    background-color: var(--secondary-lighten-60);
}
.row-heading--lighten-60{
    background-color: var(--secondary-lighten-60);
    color: var(--secondary-lighten-10);
}

.pay-link{
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 14px;
    background-color: transparent;
    border: 1px solid var(--primary-10);
    color: var(--primary-10);

    border-radius: 14px;
}
.pay-link .pay-link--img{
    min-width: 113px;
    max-width: 113px;
    height: 80px;
}

.or-line{
    position: relative;
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
}
.or-line .or-text{
    font-size: 18px;
    color: var(--white);
    display: block;
    text-align: center;
    padding-left: 20px;
    padding-right: 20px;
    background-color: var(--darck-color);
    z-index: 1;
}
.or-line::after{
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    display: block;
    background-color: var(--primary-10);

    top: 50%;
    transform: translateY(-50%);

}

.card-input{
    border: 1px solid #262626;
    background-color: #1B1B1B;
    border-radius: 8px;
}
.icon-credit .icon-input{
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;

    padding-right: 12px;
    height: 100%;
}
.icon-right .icon-input{
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translateY(-50%);
}
.icon-right .input-custom__input{
    padding-right: 56px;
}
.icon-input{pointer-events:none;}
.icon-credit .icon-input .ico:not(:last-child){
    margin-right: 12px;
}
.input-custom--light .input-custom__input{
    border-color: rgba(255, 255, 255, 0.50);
    color: var(--white);
    font-size: 16px;
}
.input-custom--light .input-custom__input::placeholder{
    color: var(--white);
}
.icon-credit .input-custom__input{
    padding-right: calc(113px + 16px + 16px);
}
.input-custom--check{
    overflow: hidden;
}
.input-custom--check .custom-checkbox{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    border: 1px solid rgba(255, 255, 255, 0.50);
    border-radius: 8px;
}
.input-custom--check .custom-checkbox-text{
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    width: 100%;
    color: var(--white);
}
.primary-upload-btn{
    pointer-events:all;
    cursor: pointer;
}
.common-pages--custom .common-pages-item{
    background-color: rgba(255, 255, 255, 0.16);
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 6px;
}
.common-pages--custom .common-pages-item-text{
    color: rgba(255, 255, 255, 0.6);
}
.ico-24{
    min-width: 24px;
    max-width: 24px;
    height: 24px;
}
.btn-shadow-inset{
    border: 1px solid var(--primary-10);
    background: #313131;
    box-shadow: 2px 0px 17px 0px rgba(234, 130, 7, 0.30) inset;
}
.custom-checkbox-fake.custom-checkbox-fake-16{
    width: 16px;
    height: 16px;
    flex: 0 0 16px;
}
.color-white-05{
    color: rgba(255, 255, 255, 0.50) !important;
}
.btn-def{
    background: #313131;
    color: #fff;
}
.textarea-with-suggestion--93 textarea{
    min-height: 93px;
}
.range-slider.noUi-target{
    box-shadow: none;
    background-color: #D9D9D9;
    border-radius: 300px;
    border: none;
    height: 11px;
}
.range-slider.noUi-target .noUi-connect{
    background-color: #F60;
}
.range-slider.noUi-target .noUi-handle{
    width: 18px;
    height: 18px;
    top: 50%;
    transform: translateY(-50%);

    box-shadow: none;
    border: none;
    background-color: var(--white);
    border-radius: 300px;
    /*right: 0;*/

}
/*
.range-slider.noUi-target.one-slider .noUi-handle-lower{
    right: 0 !important;
}
.range-slider.noUi-target:not(.one-slider) .noUi-handle-upper{
    right: 0 !important;
}*/
.range-slider.noUi-target .noUi-handle:before,
.range-slider.noUi-target .noUi-handle:after{
    display: none;
}
.range-slider.noUi-target .noUi-origin{
    height: inherit;
}
.range-slider.noUi-target .noUi-handle-upper .noUi-tooltip{
    background-color: #161616;
}
.range-slider.noUi-target .noUi-handle-lower .noUi-tooltip{
    left: /*100%*/ 0;
    padding-left: 0;
    transform: translate(0%, 0);
}
.range-slider.noUi-target .noUi-tooltip{
    background-color: transparent;
    border: none;
    color: #F60;
    font-size: 12px;
}
.range-slider.noUi-target .noUi-pips{
    padding: 0px 0;
    height: 0px;
    top: -100%;
    left: 0;
    width: 100%;
    margin-top: -19px;
}
.range-slider.noUi-target .noUi-marker:not(
    [data-value]
){
    display: none;
}
.range-slider.noUi-target .noUi-value{
    transform: translate(-50%,0);
    top: 0;
    color: #F60;
    font-size: 12px;

    left: 100%;
    transform: translate(-100%, 0);
    opacity: 0.9;
}
.range-slider.one-slider--custom-message.noUi-target .noUi-connect{
    background-color: var(--primary-10);
}
.range-slider.one-slider--custom-message.noUi-target .noUi-tooltip{
    background-color: var(--white);
    color: var(--primary-10);
    margin-bottom: 5px;
}
.range-slider.one-slider--custom-message.noUi-target .noUi-tooltip::after{
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 5px 6.5px 0 6.5px;
    border-color: var(--white) transparent transparent transparent;
    position: absolute;
    display: block;
    bottom: -4px;
    left: 50%;
    transform: translateX(-50%);
}
.range-slider.noUi-target.one-slider--custom-message{
    height: 8px;
    background-color: rgba(255, 255, 255, 0.25);
    border-radius: 0;
}
.range-slider.noUi-target.one-slider--custom-message .noUi-handle{
    background-color: var(--primary-10);
}

.widget--slider{
    display: flex;
    white-space: nowrap;
    position: relative;
}
.widget--slider .widget--slider--btn{
    color: var(--white);
    height: 48px;
    min-height: 48px;
}
.switch-bool{
    font-size: 12px;
    overflow: hidden;

    display: flex;
    width: 100%;
    min-width: 130px;
    max-width: 130px;
    user-select: none;
    height: 30px;
}


.switch-bool input:checked ~ .switch-text2{
    color: #000;
}
.switch-bool input:not(:checked) ~ .switch-text1{
    color: #000;
}
.switch-bool input:checked ~ .splitter{
    left: 65px;
}

.switch-bool{
    position:relative;
    background-color:#EA8207;
}
.switch-bool .switch-text{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    color: #FFF;
    position: relative;
    height: 100%;
    z-index: 1;
    transition: all 0.3s;
}
.switch-bool .splitter{
    background-color: #fff;
    content: "";
    position: absolute;
    height: 100%;
    width: 50%;
    display: block;
    top: 0;
    left: 0;
    border: 5px solid #EA8207;
    transition: all 0.3s;
}


.group-cols{
    display: flex;
    flex-wrap: wrap;
    margin-top: 0;
    
}
.group-cols > .group-col{
    flex: 1;
    white-space: nowrap;
    width: 100%;
}
.group-cols .widget--slider{
    padding-bottom: 10px;
}
.group-cols .range-labels{
    margin-top: 10px;
}
.group-cols > .group-col:not(:last-child) .widget--slider{
    margin-right: 18px;
}
.group-cols .input-custom__label{
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}
.def-row-px{
    padding-left: 0;
    padding-right: 0;
}
.def-row-px > div{
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
}
.select-default .choices__list--single{
    padding-right: 26px;
}
.range-slider .noUi-connects{
    border-radius: 0 !important;
}
.range-panel{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.range-labels{
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #F60;
    font-size: 12px;
    opacity: 0.9;
    
    position: absolute;
    width: 100%;
    top: calc(-100% - 18px);
}

.click-widget--btn{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    color: rgba(255, 255, 255, 0.50);
}
.click-widget--btn:hover{
    color: #F60;
}

.range-slider.one-slider .noUi-connects{
    border-radius: 300px !important;
}
.range-slider.one-slider .noUi-tooltip{
    transform: translate(-50%, 0) !important;
    left: 50% !important;
    bottom: 120%;
    padding: 5px !important;
}
.range-slider.none-tooltips .noUi-tooltip{
    display: none !important;
}

.range-slider.slider-price .noUi-tooltip{
    -webkit-transform: translate(-50%, 0) !important;
    transform: translate(-50%, 0) !important;
    left: 50% !important;
    top: 100%;
    bottom: auto !important;
    margin-top: 5px;

    background-color: var(--white) !important;
    overflow: hidden;
    min-width: 65px;
    border-radius: 5px;

    padding-left: 0 !important;
    padding-right: 0 !important;
}
.range-slider.slider-price .noUi-tooltip .tooltip-info{
    color: #707070;
    font-size: 9px;
    font-style: normal;
}
.range-slider.slider-price .noUi-tooltip .tooltip-price{
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
}
.range-slider.noUi-target.slider-price{
    background-color: #A3A2A1;
    border: 2px solid var(--white);
    height: 5px;
}
.range-slider.noUi-target.slider-price .noUi-handle{
    background-color: var(--primary);
    width: 15px;
    height: 15px;
}

.widget-price{
    display: flex;
    flex-direction: column;
    border: 1px solid #262626;
    background: #1B1B1B;
    border-radius: 8px;
    padding: 12px 16px;
}
.widget-price--text{
    color: rgba(255, 255, 255, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}
.widget-price--checkers{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: -13px;
}
.widget-price--checkers li{
    display: flex;
    margin-bottom: 13px;
}
.widget-price--checkers li:not(:last-child){
    margin-right: 13px;
}
.checker-price{
    display: flex;
    cursor: pointer;
}
.checker-box{
    display: flex;
    align-items: center;
    background-color: transparent;
    border-radius: 8px;
    border: 1px solid var(--primary);
    color: rgba(255, 255, 255, 0.50);
    font-size: 12px;
    font-weight: 400;
    line-height: normal;
    min-height: 30px;

    padding: 6px 11px;
}
.checker-box .checker-box--icons{
    width: 20px;
    height: 20px;
    position: relative;
}
.checker-box .checker-ico.--checked{
    opacity: 0;
}
.checker-box .checker-box--icons > *{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}
.checker-price input:checked ~ .checker-box .checker-box--icons .checker-ico.--checked{
    opacity: 1;
}
.checker-price input:checked ~ .checker-box .checker-box--icons .checker-ico:not(.--checked){
    opacity: 0;
}
.checker-price input:checked ~ .checker-box{
    background-color: var(--primary);
    color: var(--white);
}
.btn-white{
    background-color: var(--white);
    color: var(--secondary-lighten-30);
}
.btn-custom-41{
    padding: 9px 15px;
    min-height: 41px;
}
.btn-custom-38{
    padding: 5px 18px;
    min-height: 38px;
}

.btn-custom-21{
    padding: 0px 6px;
    min-height: 21px;
}
.btn-custom-27{
    padding: 2px 6px;
    min-height: 27px;
}
.btn-custom-32{
    padding: 3px 10px;
    min-height: 32px;
}
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
.form-search .form-search__input{
    padding-right: 60px;
}

.widget-card{
    display: flex;
    flex-direction: column;
    border: 1px solid #262626;
    background: #1B1B1B;
    border-radius: 8px;
}
.def-list{
    display: flex;
    flex-direction: column;
}
.def-list-7 > *:not(:last-child){
    margin-bottom: 7px;
}
.task-block{
    display: flex;
    flex-direction: column;
    background-color: rgba(112, 112, 112, 0.25);
}
.task-block--head{
    display: flex;
    align-items: center;
}
.task-block--head > *:not(:last-child){
    margin-right: 10px;
}

.input-custom__input.input-custom__input-style-2{
    background-color: transparent;
    border-color: var(--white-50);
}
.input-custom__input.input-custom__input-style-2::placeholder{
    color: rgba(255, 255, 255, 0.50);
}
.btn--custom-border{
    border-color: var(--white-50);
    background: rgba(255, 255, 255, 0.12);
    color: var(--primary-10);
}
textarea::-webkit-scrollbar{
    display: none;
}

.dropdown-select .choices__inner .choices__list--single .choices__item.choices__item--selectable{
    display: none;
}
.dropdown-select .choices:after{
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
}