@media (max-width: 1199.98px) {

}

@media (max-width: 991.98px) {

}

@media (max-width: 767.98px) {

}

@media (max-width: 575.98px) {
    .product-description__group{
        flex-direction: column;
        align-items: inherit;
    }

    .product-description__item-text{
        max-width: max-content;
    }

    .product-description__list{
        margin-right: 0 !important;
        margin-bottom: 20px;
    }

    .product-description__textarea-footer{
        flex-direction: column;
    }
}

@media (min-width: 576px) {

}

@media (min-width: 768px) {

}

@media (min-width: 992px) {

}

@media (min-width: 1200px) {

}
